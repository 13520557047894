import styled from "styled-components";
import ReactCodeInput from "react-code-input";

export const CodeInput = styled(ReactCodeInput)`
  display: flex !important;
  justify-content: center;
  gap: 16px;
  padding-bottom: 16px;

  input {
    font-family: Montserrat;
    font-size: 28px;
    text-align: center;
    border: 1px solid lightgrey;
    border-radius: 8px;
    height: 2em;
    width: 2em;
    margin: 0;
    outline-color: var(--rww-brand-purple);
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    -moz-appearance: textfield;
  }
`;
