import React, { useState } from "react";

import OTPEnterCode from "../components/OTP/Email/EnterEmailCode";
import OTPEnterEmail from "../components/OTP/Email/EnterEmail";
import OTPFailed from "../components/OTP/Email/EmailFailed";
import OTPSuccess from "../components/OTP/Email/EmailSuccess";
import OTPResend from "../components/OTP/Email/EmailResend";
import LoginPageLayout from "../components/Layout/LoginPageLayout";
import Meta from "../components/Meta";


export const otpSteps = {
  enterEmail: "EnterEmail",
  enterCode: "EnterCode",
  failed: "Failed",
  success: "Success",
  resend: "Resend",
};

const EmailOTP = () => {
  const [otpStep, setOTPStep] = useState(otpSteps.enterEmail);

  const getCurrentView = () => {
    switch (otpStep) {
      case otpSteps.enterEmail:
        return OTPEnterEmail;
      case otpSteps.enterCode:
        return OTPEnterCode;
      case otpSteps.failed:
        return OTPFailed;
      case otpSteps.success:
        return OTPSuccess;
      case otpSteps.resend:
        return OTPResend;
      default:
        return OTPEnterEmail;
    }
  };

  const CurrentView = getCurrentView();

  return (
    <LoginPageLayout>
      <Meta
        title=" Register here to find part-time jobs | redwigwam - Workers"
        description="Registration page for workers looking to find part time work"
        keywords="part time work, temporary work, students, working mums, recently retired, looking for temporary workers"
      />
      <CurrentView setOTPStep={setOTPStep} />
      
    </LoginPageLayout>
  );
};
export default EmailOTP;
