
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import dataService from "../../../utils/data.service";
import { ButtonWrap, OTPForm, OTPHeading } from "../../Common/OTP";
import { OpenEmailIcon } from "../../../assets/svg/OpenEmailIcon";
import { ErrorDisplay } from "../../Common";
import {
  resetOTPCode,
  selectOTP,
  updateOTPEmail,
} from "../../../store/otp.slice";
import { otpSteps } from "../../../pages/EmailOTP";
import { useNavigate } from "react-router-dom";
import { ERROR_CONST } from "../../../utils/errors";
import { Button, Icons, Input } from "@rww/fe-common";

const OTPResend = ({ setOTPStep }) => {
  const dispatch = useDispatch();
  const otp = useSelector(selectOTP);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  const handleRegistration = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);

    dataService
      .emailSendOTP(otp.email)
      .then((response) => {
        if (response?.data?.verifiedMobile) {
          // user has previously completed both Email && Mobile otp but not fully signed up

          navigate("/sign-up", { replace: true });
        } else if (response?.data?.verifiedEmail) {
          // user has previously completed just Email otp but not fully signed up

          navigate("/otp-mobile", { replace: true });
        } else {
          dispatch(resetOTPCode());
          setOTPStep(otpSteps.enterCode);
        }
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
        setError(ERROR_CONST.generic);
      });
  };

  const handleChange = (e) => {
    dispatch(updateOTPEmail(e.target.value));
  };

  return (
    <>
      <OTPHeading
        icon={<OpenEmailIcon />}
        title="Resend"
        subtitle="Update your email address below if it's not correct"
      />

      <OTPForm onSubmit={handleRegistration}>
        <Input
          label="Email address"
          name="email"
          type="email"
          onChange={handleChange}
          value={otp.email}
          data-test-id="otp-email-change-email"
          fullWidth
        />

        <ButtonWrap>
          <Button
            primary
            fullWidth
            type="submit"
            endIcon={<Icons.ArrowRight />}
            disabled={loading || !otp.email}
            data-test-id="otp-email-change-email-submit"
          >Send new code</Button>
          {error && <ErrorDisplay>{error}</ErrorDisplay>}
        </ButtonWrap>
      </OTPForm>
    </>
  );
};

export default OTPResend;
