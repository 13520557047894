import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { PageHeading } from "../CommonQuestionBits";
import { Checkbox } from "@rww/fe-common";

import QuestionWrap from "../QuestionWrap";
import dataService, { FIELD_CONST } from "../../../utils/data.service";
import { ErrorDisplay } from "../../Common";
import Markdown from "../../Markdown";
import { Button } from "@rww/fe-common";
import { Done, Replay } from "@material-ui/icons";
import { IconCircle } from "../../Common/OTP";
import { ERROR_CONST } from "../../../utils/errors";


const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 24px;

  max-height: calc(100vh - 200px);
`;

const TermContainer = styled.div`
  flex-grow: 1;

  position: relative;

  overflow-y:auto;
`;


const StyledIconCircle = styled(IconCircle)`
  margin-top: 24px;
`;

const StyledError = styled(ErrorDisplay)`
  text-align: center;
`;

const Loading = styled.p`
  text-align: center;
`;

const StyledMarkdown = styled(Markdown)``;

const StyledCheckbox = styled(Checkbox)`
  margin: 16px 8px;
  .radio__label {
    color: #918787;
    font-size: 12px;
  }
`;

const Name = styled.p`
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;

  text-align: center;
`;
const DateAccepted = styled.p`
  text-align: center;
  font-size: 14px;
  margin-bottom: 0;
`;
const IPAddress = styled.p`
  font-size: 12px;
  text-align: center;
  color: #8c8181;
  margin-top: 8px;
`;

const ErrorWrap = styled.div`
  padding: 0 24px;
`;

const Terms = ({
  handleChange,
  handleBlur,
  values,
  submitError,
  isSubmitting,
  submitForm,
}) => {
  const [terms, setTerms] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  const [showReview, setShowReview] = useState(false);
  const [acceptDate, setAcceptDate] = useState();
  const [ip, setIP] = useState();

  const getTerms = async () => {
    try {
      setError(false);
      setLoading(true);
      const response = await dataService.getTerms();

      const terms = response?.data?.body;
      setTerms(terms);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
      setError(ERROR_CONST.terms);
    }
  };

  useEffect(() => {
    getTerms();
  }, []);

  useEffect(() => {
    dataService.getIP().then((response) => setIP(response?.data?.ip));
  }, []);

  const handleAcceptClick = () => {
    const now = new Date();
    const date = now.toLocaleDateString("en-gb", {
      weekday: "short",
      day: "numeric",
      month: "short",
      year: "numeric",
    });
    const time = now.toLocaleTimeString("en-gb", {
      hour: "numeric",
      minute: "numeric",
    });
    setAcceptDate(`${date} at ${time}`);
    setShowReview(true);
  };

  const handleSubmit = () => {
    submitForm();
  };

  const handleClick = () => {
    if (showReview) {
      handleSubmit();
    } else {
      handleAcceptClick();
    }
  };


  return (
    <QuestionWrap
      buttonType="button"
      buttonOnClick={handleClick}
      buttonText={showReview ? "Finish" : "Accept"}
      disabled={isSubmitting || !values[FIELD_CONST.terms]}
      submitError={submitError}
      loading={isSubmitting}
    >
      {showReview && (
        <>
          <PageHeading>Terms and conditions</PageHeading>
          <PageHeading>Accepted</PageHeading>
          <StyledIconCircle>
            <Done />
          </StyledIconCircle>
          <Name>
            {values?.[FIELD_CONST.firstName]} {values?.[FIELD_CONST.lastName]}
          </Name>
          <DateAccepted>{acceptDate}</DateAccepted>
          {ip && <IPAddress>IP address: {ip}</IPAddress>}
        </>
      )}

      {!showReview && (
        <ContentWrap>
          <PageHeading>Terms and conditions</PageHeading>
          <TermContainer>
            {error && (
              <ErrorWrap>
                <StyledError>{error}</StyledError>
                <Button
                  onClick={getTerms}
                  endIcon={<Replay />}
                  secondary
                  fullWidth
                >Retry</Button>
              </ErrorWrap>
            )}
            {loading ? (
              <Loading>...loading</Loading>
            ) : (
              <StyledMarkdown text={terms} />
            )}

          </TermContainer>

          <StyledCheckbox
            label="I accept these terms and conditions"
            name={FIELD_CONST.terms}
            onChange={handleChange}
            onBlur={handleBlur}
            checked={values[FIELD_CONST.terms]}
            disabled={error || loading}
            size="24px"
            data-test-id="sign-up-terms-checkbox"
          />
        </ContentWrap>
      )}
    </QuestionWrap>
  );
};

export default Terms;
