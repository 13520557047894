import styled from "styled-components";

import logoGoogle from "../../assets/images/logo-google.png";
import logoFacebook from "../../assets/images/logo-facebook-blue.png";

const SocialSeparator = styled.div`
  position: relative;
  border-top: 1px solid #c8c3c3;
  width: 100%;
  margin: 8px 0 24px 0;
  :after {
    content: "${(props) => props?.$word ?? "Or"}";
    color: #c8c3c3;

    position: absolute;
    background: white;
    left: 50%;
    top: -8px;
    line-height: 16px;
    font-size: 12px;
    padding: 0 8px;
    transform: translateX(-50%);
  }
`;

const SocialButton = styled.img`
  box-shadow: none;
  padding: 0 16px;
  cursor: pointer;
`;

const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Socials = ({ handleGoogle, handleFacebook, separatorText = "Or" }) => {
  return (
    <>
      <SocialSeparator $word={separatorText} />
      <ButtonWrap>
        <SocialButton
          onClick={handleGoogle}
          src={logoGoogle}
          data-test-id="social-login-google"
        />
        <SocialButton
          onClick={handleFacebook}
          src={logoFacebook}
          data-test-id="social-login-facebook"
        />
      </ButtonWrap>
    </>
  );
};

export default Socials;
