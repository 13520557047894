import React, { useCallback, useEffect, useState } from "react";

import RTWLayout from "../components/Layout/RTWLayout";
import authService from "../utils/auth.service";
import { useNavigate } from "react-router-dom";
import { Button } from "@rww/fe-common";
import { PageHeading } from "../components/Common";

const Logout = () => {
  const navigate = useNavigate();
  const [loggingOut, setLoggingOut] = useState(true);
  const [error, setError] = useState(false);

  const logOut = useCallback(async () => {
    try {
      setLoggingOut(true);
      const response = await authService.logout();

      if (response?.data?.success) {
        navigate("/", { replace: true });
      } else {
        throw new Error();
      }
    } catch (e) {
      setLoggingOut(false);
      setError(true);
    }
  }, [navigate]);

  useEffect(() => {
    logOut();
  }, [logOut]);

  return (
    <RTWLayout loading={loggingOut} overlay loadingText="Logging out...">
      {error && (
        <div>
          <PageHeading>Log out error</PageHeading>
          <Button
            primary
            onClick={() => navigate("/", { replace: true })}
            fullWidth
          >Back to login</Button>
        </div>
      )}
    </RTWLayout>
  );
};

export default Logout;
