import authService from "./auth.service";

export const getSSOToken = async () => {
  try {
    const ssoResponse = await authService.getSSOToken();
    if (ssoResponse?.data?.token?.result) {
      return {
        id: ssoResponse?.data?.legacyId,
        token: ssoResponse?.data?.token?.token,
      };
    }
  } catch (e) {
    console.error("[SSO TOKEN REFRESH]", e);
  }
};

export const OW_PAGES = {
  profile: "myprofile",
  jobs: "job-opportunities",
};

export const loginSSO = async (page = OW_PAGES.profile) => {
  const { id, token } = await getSSOToken();
  const inputs = Object.entries({ legacyid: id, token, page });

  const form = document.createElement("form");

  form.style.display = "none";

  form.setAttribute("action", process.env.REACT_APP_OLD_WORLD_SSO_LOGIN);
  form.setAttribute("method", "POST");

  inputs.forEach(([key, value]) => {
    const input = document.createElement("input");
    input.setAttribute("name", key);
    input.setAttribute("value", value);
    form.appendChild(input);
  });

  document.body.appendChild(form);

  form.submit();
};
