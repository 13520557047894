import { Copy, Logo, PageHeading } from "../Common";

import { BackButtonIcon } from "../../assets/svg/BackButton";
import { JobSearchIcon } from "../../assets/svg/JobSearchIcon";
import loginLogo from "../../assets/images/login-logo.png";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";

const StyledBackButton = styled(BackButtonIcon)`
  color: var(--rww-brand-purple);
  position: absolute;
  left: 16px;
  top: 24px;
  cursor: pointer;
`;

const JobSearch = styled.a`
  position: absolute;
  right: 16px;
  top: 24px;
  cursor: pointer;
  color: var(--rww-brand-purple);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  text-decoration: none;
`;

const JobSearchText = styled.p`
  margin: 0;
`;

const jobsLinks = process.env.REACT_APP_EXTERNAL_LINK_JOBS;

const LoginPageHeading = ({ title, subtitle }) => {
  const navigate = useNavigate();

  const showBack = document.referrer.indexOf("redwigwam") >= 0;

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <>
      <Logo src={loginLogo} alt="redwigwam logo" />
      <PageHeading tabIndex="1">{title}</PageHeading>
      <Copy>{subtitle}</Copy>

      {showBack && (
        <div onClick={handleBack}>
          <StyledBackButton />
        </div>
      )}
      <JobSearch href={jobsLinks} data-test-id="login-jobs">
        <JobSearchIcon />
        <JobSearchText>jobs</JobSearchText>
      </JobSearch>
    </>
  );
};

export default LoginPageHeading;
