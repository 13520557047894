import { ErrorDisplay, StyledAnchor } from "../components/Common";
import { useEffect, useState } from "react";

import { Button, Icons } from "@rww/fe-common";
import { ERROR_CONST } from "../utils/errors";
import { EmailOutlined } from "@material-ui/icons";
import LoadingSplash from "../components/Auth/LoadingSplash";
import LoginPageHeading from "../components/Layout/LoginPageHeading";
import LoginPageLayout from "../components/Layout/LoginPageLayout";
import Meta from "../components/Meta";
import { Input } from "@rww/fe-common";
import authService from "../utils/auth.service";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useMagicToken } from "../utils/general";
import useURLParams from "../utils/useURLParams.hook";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export default function MagicLink() {
  const [emailAddress, setEmailAddress] = useState();
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  const {
    magicToken,
    magicTokenRedirect,
    error: magicLinkError,
  } = useMagicToken();

  const { advertId, referralSource } = useURLParams();

  useEffect(() => {
    setSubmitted(false);
  }, [magicToken, magicTokenRedirect]);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setError(false);
      setLoading(true);
      const response = await authService.postMagicLinkRequest({
        emailAddress,
        redirectUrl: magicTokenRedirect,
        advertId,
        referralSource,
      });

      if (response?.data?.success) {
        setSubmitted(true);
      }
      setLoading(false);
    } catch (e) {
      setError(true);
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setEmailAddress(e.target.value);
  };

  const handleBack = () => {
    sessionStorage.setItem("magicTokenRedirect", magicTokenRedirect);
    navigate("/");
  };

  const subtitle = submitted
    ? "Done! If you have an account, a magic link has been sent to your inbox"
    : "Enter your email below and we'll send you a login link. Simple!";

  if (magicToken && !magicLinkError) {
    return <LoadingSplash />;
  }

  return (
    <LoginPageLayout>
      <Meta
        title="Log in with magic link to find part time work | redwigwam - Workers"
        description="Magic link log in page for people looking for part time or temporary work"
        keywords="part time work, temporary work, students, working mums, recently retired, looking for temporary workers"
      />
      <LoginPageHeading title="Magic Link" subtitle={subtitle} />
      {!submitted && (
        <Form onSubmit={onSubmit}>
          <Input
            label="Email address"
            type="email"
            name="emailAddress"
            required
            fullWidth
            icon={<EmailOutlined />}
            value={emailAddress}
            onChange={handleChange}
            disabled={loading}
          />
          {error && <ErrorDisplay>{ERROR_CONST.generic}</ErrorDisplay>}
          {magicLinkError && <ErrorDisplay>{magicLinkError}</ErrorDisplay>}
          <Button primary fullWidth disabled={loading} endIcon={<Icons.ArrowRight />}>Submit</Button>
        </Form>
      )}

      <StyledAnchor onClick={handleBack} data-test-id="reset-password-back">
        Back to Log-in
      </StyledAnchor>
    </LoginPageLayout>
  );
}
