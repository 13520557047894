import React from "react";
import { OTPHeading } from "../components/Common/OTP";
import LinkOffIcon from "@material-ui/icons/LinkOff";

import { Button, Icons } from "@rww/fe-common";
import LoginPageLayout from "../components/Layout/LoginPageLayout";
import { useNavigate } from "react-router-dom";
import authService from "../utils/auth.service";
import Meta from "../components/Meta";

const OAuthDenied = () => {
  const navigate = useNavigate();


  let title;
  let subtitle;
  let ctaText = "Try again";
  const searchParams = new URLSearchParams(window.location.search);

  const existingType = searchParams.get("existingtype")?.toLowerCase() || "";
  const type = searchParams.get("type")?.toLowerCase() || "";
  const msg = searchParams.get("msg")?.toLowerCase() || "";
  const detail = searchParams.get("detail")?.toLowerCase() || "";

  switch (msg) {
    case "unabletosigninnotregistered":
      title = `Failed to sign in, you are not registered`;
      subtitle = `Please register and try again`;
      ctaText = "Register";
      break;
    case "alreadysignedup":
      title = `Failed to connect to your ${type} account`;
      subtitle = `You have already registered this email address using ${existingType}`;
      ctaText = "Sign in";
      break;
    case "noemailaddr":
      title = `Failed to connect to your ${type} account`;
      subtitle = `We were unable to retrieve all the information required to create an account. Please review your ${type} privacy settings`;
      break;
    default:
      title = `Failed to connect${type ? ` to ${type}` : ""}`;
      subtitle =
        "Please try again, or simply use your email address to sign up";
      break;
  }

  const handleLogInViaSocial = (existingType) => {
    switch (existingType.toLowerCase()) {
      case "facebook":
        authService.signInFacebookOAuth();
        break;
      case "google":
        authService.signInGoogleOAuth();
        break;
      default:
        handleBackToRegister();
        break;
    }
  };

  const handleBackToRegister = () => {
    navigate("/otp-email");
  };

  const handleClick = () => {
    switch (msg) {
      case "alreadysignedup":
        handleLogInViaSocial(existingType);
        break;
      default:
        handleBackToRegister();
        break;
    }
  };
  return (
    <LoginPageLayout>
      <Meta
        title="Failed | Return from social | redwigwam - Workers"
        description="Registration page for workers looking to find part time work"
        keywords="part time work, temporary work, students, working mums, recently retired, looking for temporary workers"
      />
      <OTPHeading icon={<LinkOffIcon />} title={title} subtitle={subtitle} />
      <Button
        endIcon={<Icons.ArrowRight />}
        text={ctaText}
        onClick={handleClick}
        primary
        fullWidth
        data-test-id="oauth-denied-back"
      />

      {detail && (
        <detail>
          <summary>Error Summary</summary>
          {detail}
        </detail>
      )}
    </LoginPageLayout>
  );
};

export default OAuthDenied;
