import dataService from "./data.service";

const recaptchaV3Threshold = process.env.REACT_APP_RECAPTCHA_V3_THRESHOLD;
const recaptchaV3Key = process.env.REACT_APP_RECAPTCHA_V3_KEY;

const recaptchaV2Key = process.env.REACT_APP_RECAPTCHA_V2_KEY;

const recaptchaEvents = {
  v2: "recaptcha-v2-response",
  v3: "recaptcha-v3-response",
};

export const v2Id = "grecaptcha-v2-root";

async function getUserCaptchaToken(action) {
  return window.grecaptcha.enterprise.execute(recaptchaV3Key, { action });
}

function handleError(error, captchaVersion) {
  const event = new CustomEvent(captchaVersion, {
    detail: { success: false, detail: { error, version: captchaVersion } },
  });

  window.dispatchEvent(event);
}

function handleV3Score(response) {
  const error = response?.data?.error;
  const score = response?.data?.score ?? 0;
  const reasons = response?.data?.reasons;
  let event;

  if (error) {
    event = new CustomEvent(recaptchaEvents.v3, {
      detail: { success: false, detail: { error, version: "V3" } },
    });
  } else {
    event = new CustomEvent(recaptchaEvents.v3, {
      detail: {
        success: score >= recaptchaV3Threshold,
        detail: { score, reasons, version: "V3" },
      },
    });
  }

  window.dispatchEvent(event);
}

async function executeV3Captcha(action) {
  try {
    const token = await getUserCaptchaToken(action);
    const scoreResponse = await dataService.getCaptchaScore(token, action);
    handleV3Score(scoreResponse);
  } catch (e) {
    handleError(e, "V3");
  }
}

function handleV2Score(response) {
  const success = response?.data?.success;
  const errorCodes = response?.data?.["error-codes"] ?? [];

  const event = new CustomEvent(recaptchaEvents.v2, {
    detail: { success: success, detail: { errorCodes, version: "V2" } },
  });

  if (errorCodes?.length > 0) {
    const recaptchaWrap = document.getElementById(v2Id);

    if (recaptchaWrap) {
      const errorMessage = document.createElement("span");
      errorMessage.innerText =
        "Error submitting recaptcha, please refresh and try again";
      errorMessage.style.color = "red";
      recaptchaWrap.appendChild(errorMessage);
    } else {
      alert("Error submitting recaptcha, please refresh and try again");
    }
  }

  window.dispatchEvent(event);
}

async function v2Callback(token) {
  try {
    const scoreResponse = await dataService.getV2Result(token);
    handleV2Score(scoreResponse);
  } catch (e) {
    handleError(e, "V2");
  }
}

async function executeV2Captcha(action) {
  if (!document.getElementById(v2Id)) {
    console.error("RECAPTCHA DIV NOT DEFINED");
    v2Callback();
    return;
  }
  window.grecaptcha.render(v2Id, {
    sitekey: recaptchaV2Key,
    callback: (token) => v2Callback(token, action),
  });
}

function handleCaptchaResponse(e, resolve) {
  console.log("[RECAPTCHA]", e?.detail);
  resolve(e?.detail?.success ?? false);
}

export async function executeCaptcha(action) {
  return new Promise((resolveCaptcha) => {
    window.grecaptcha.ready(async function () {
      // try silent recaptcha first
      const passedV3 = await new Promise((resolveV3) => {
        window.addEventListener(recaptchaEvents.v3, (e) =>
          handleCaptchaResponse(e, resolveV3)
        );
        executeV3Captcha(action);
      });

      if (passedV3) {
        resolveCaptcha(true);
        return;
      }
      //  fallback to puzzle recaptcha
      const passedV2 = await new Promise((resolveV2) => {
        window.addEventListener(recaptchaEvents.v2, (e) =>
          handleCaptchaResponse(e, resolveV2)
        );
        executeV2Captcha(action);
      });

      resolveCaptcha(passedV2);
    });
  });
}
