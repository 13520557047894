import axios from "axios";

export const endpoints = {
  signup: {
    get: {
      sectors: "/Api/Lookups/GetAllRoles",
      terms: "/Api/Signup/GetTerms",
    },
    post: {
      captcha: "/Api/Captcha/Request",
      siteVerify: "/Api/Captcha/SiteVerify",

      register: "/Api/Signup/PostDetails",
      otp: {
        email: {
          send: "/Api/Signup/SendCode",
          verify: "/Api/Signup/VerifyCode",
          resend: "/Api/Signup/ResendCode",
        },
        mobile: {
          send: "/Api/Signup/SendSMS",
          verify: "/Api/Signup/VerifySmsCode",
          resend: "/Api/Signup/ResendSMS",
        },
      },
      password: {
        reset: "/Api/Signup/ResetPassword",
        set: "/Api/Signup/SetPassword",
      },
    },
  },
  data: {
    applicationStats: "/Api/Me/GetApplicationStats",
    rtwStats: "/Api/RTW/GetProgress",
  },
};

export const FIELD_CONST = {
  email: "EmailAddress",
  firstName: "FirstName",
  lastName: "LastName",
  dob: "DateOfBirth",
  password: "Password",
  postcode: "Postcode",
  distance: "Distance",
  dataConsent: "DataConsent",
  terms: "Terms",
  mobile: "MobileNumber",
  emailCode: "Code",
  smsCode: "Code",
  utmTerm: "UTMTerm",
  utmSource: "UTMSource",
  utmMedium: "UTMMedium",
  advertId: "AdvertId",
  referralSource: "ReferralSource",
};

class DataService {
  getSectors = async () => {
    return axios.get(endpoints.signup.get.sectors);
  };

  getCaptchaScore = async (token, expectedAction) => {
    return axios.post(endpoints.signup.post.captcha, {
      token,
      expectedAction,
    });
  };

  getV2Result = async (token) => {
     return axios.post(endpoints.signup.post.siteVerify, {
      response:token,
    });
  };

  emailSendOTP = async (
    emailAddress,
    utmTerm,
    utmSource,
    utmMedium,
    advertId,
    referralSource
  ) => {
    return axios.post(endpoints.signup.post.otp.email.send, {
      [FIELD_CONST.email]: emailAddress,
      [FIELD_CONST.utmTerm]: utmTerm,
      [FIELD_CONST.utmSource]: utmSource,
      [FIELD_CONST.utmMedium]: utmMedium,
      [FIELD_CONST.advertId]: advertId,
      [FIELD_CONST.referralSource]: referralSource,
    });
  };

  emailResendOTP = async (emailAddress) => {
    return axios.post(endpoints.signup.post.otp.email.resend, {
      [FIELD_CONST.email]: emailAddress,
    });
  };

  emailVerifyOTP = async (emailAddress, code) => {
    return axios.post(endpoints.signup.post.otp.email.verify, {
      [FIELD_CONST.email]: emailAddress,
      [FIELD_CONST.emailCode]: code,
    });
  };

  mobileSendOTP = async (number, email) => {
    return axios.post(endpoints.signup.post.otp.mobile.send, {
      [FIELD_CONST.mobile]: number,
      [FIELD_CONST.email]: email,
    });
  };

  mobileResendOTP = async (number, email) => {
    return axios.post(endpoints.signup.post.otp.mobile.resend, {
      [FIELD_CONST.mobile]: number,
      [FIELD_CONST.email]: email,
    });
  };

  mobileVerifyOTP = async (number, email, code) => {
    return axios.post(endpoints.signup.post.otp.mobile.verify, {
      [FIELD_CONST.mobile]: number,
      [FIELD_CONST.email]: email,
      [FIELD_CONST.smsCode]: code,
    });
  };

  register = async (formData) => {
    return axios.post(endpoints.signup.post.register, formData);
  };

  getTerms = async () => {
    return axios.get(endpoints.signup.get.terms);
  };

  getIP = async () => {
    return axios.get("https://api64.ipify.org?format=json");
  };

  resetPassword = async (emailAddress) => {
    return axios.post(endpoints.signup.post.password.reset, { emailAddress });
  };

  setPassword = async (confirmToken, newPassword) => {
    return axios.post(endpoints.signup.post.password.set, {
      confirmToken,
      newPassword,
    });
  };

  getApplicationStats = async () => {
    return axios.get(endpoints.data.applicationStats);
  };

  getRTWStats = async () => {
    return axios.get(endpoints.data.rtwStats);
  };
}

const dataService = new DataService();

export default dataService;
