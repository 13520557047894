export const JobSearchIcon = ({ className }) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.7825 13.8331L12.6666 10.7178C12.5259 10.5772 12.3353 10.4991 12.1353 10.4991H11.6259C12.4884 9.39594 13.0009 8.00875 13.0009 6.49937C13.0006 2.90906 10.0913 0 6.50063 0C2.91 0 0 2.90906 0 6.49937C0 10.0897 2.90969 12.9987 6.50063 12.9987C8.01 12.9987 9.39781 12.4862 10.5009 11.6237V12.1331C10.5009 12.3331 10.5791 12.5238 10.7197 12.6644L13.8356 15.7797C14.1294 16.0734 14.6044 16.0734 14.895 15.7797L15.7794 14.8953C16.0731 14.6016 16.0731 14.1269 15.7825 13.8331ZM6.50063 10.9987C4.015 10.9987 2.00062 8.98812 2.00062 6.49937C2.00062 4.01406 4.01156 2 6.50063 2C8.98625 2 11.0006 4.01062 11.0006 6.49937C11.0006 8.98469 8.98969 10.9987 6.50063 10.9987ZM6.50125 3.5C5.22687 3.5 4.19375 4.53281 4.19375 5.80719C4.19375 6.83719 5.70187 8.715 6.27969 9.39656C6.38188 9.51875 6.56375 9.535 6.68625 9.43281C6.69938 9.42156 6.71156 9.40938 6.7225 9.39625C7.30031 8.71469 8.80844 6.83719 8.80844 5.80688C8.80844 4.5325 7.77531 3.49969 6.50094 3.49969L6.50125 3.5ZM6.50125 6.5C6.08687 6.5 5.75125 6.16406 5.75125 5.75C5.75125 5.33563 6.08719 5 6.50125 5C6.91531 5 7.25125 5.33563 7.25125 5.75C7.25125 6.16406 6.91531 6.5 6.50125 6.5Z"
      fill="currentColor"
    />
  </svg>
);
