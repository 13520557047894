import React from "react";
import { Button, Icons } from "@rww/fe-common";
import styled from "styled-components";
import { ErrorDisplay } from "../../Common";
const Wrap = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
  padding: 0 24px;
  margin-bottom:8px;
`;
const Question = styled.section`
  flex-grow: 1;
  flex-direction: column;
  display: flex;
`;
const StyledButton = styled(Button)`
  margin: 24px 0;
  .MuiCircularProgress-root {
    color: white;
  }
`;

const QuestionWrap = ({
  children,
  disabled,
  buttonOnClick,
  buttonType,
  buttonText = "Continue",
  submitError,
  loading,
}) => {
  return (
    <Wrap className="question-wrap">
      <Question>{children}</Question>
      {submitError && <ErrorDisplay>{submitError}</ErrorDisplay>}
      <StyledButton
        type={buttonType}
        disabled={disabled}
        onClick={buttonOnClick}
        primary
        loading={loading}
        endIcon={
          <Icons.ArrowRight />
        }
        data-test-id="sign-up-submit-button"
      >{buttonText}</StyledButton>
    </Wrap>
  );
};

export default QuestionWrap;
