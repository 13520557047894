import styled from "styled-components";

export const PageHeading = styled.h1`
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: center;
  color: var(--rww-brand-purple);
  margin: 16px 0 8px 0;
`;

export const Subheading = styled.h2`
  //styleName: Paragraph;
  font-family: Arimo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
  margin: 16px 0;
`;

export const SubSubheading = styled.h3`
  //styleName: H2 header;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;

  color: var(--rww-brand-purple);
`;

export const Copy = styled.p`
  //styleName: Paragraph small text;
  font-family: Arimo;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
`;
