import React from "react";
import styled from "styled-components";
const Background = styled.div`
  background: var(--rww-brand-purple);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const LoginWrap = styled.div`
  background: white;
  border-radius: 16px 16px 0px 0px;
  height: calc(100% - 44px);
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`;

const Children = styled.div`
  height: 100%;
  max-height: 600px;
  max-width: 400px;
  margin: 0 auto;
  padding: 0 16px;

  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
`;

const LoginPageLayout = ({ children }) => (
  <Background>
    <LoginWrap>
      <Children>{children}</Children>
    </LoginWrap>
  </Background>
);

export default LoginPageLayout;
