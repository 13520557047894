const testLength = (input) => {
  return /.{8,}/.test(input);
};

const testDigit = (input) => {
  return /\d/.test(input);
};

const testMixedCase = (input) => {
  return /[A-Z]/.test(input) && /[a-z]/.test(input);
};

const testSpecialCharacter = (input) => {
  return /\W/.test(input);
};

export const passwordRules = [
  { label: "A minimum of 8 characters", validator: testLength },
  { label: "A digit", validator: testDigit },
  {
    label: "A mix of UPPER and lower case characters",
    validator: testMixedCase,
  },
  { label: "A special character", validator: testSpecialCharacter },
];
export const validatePassword = (input) => {
  return passwordRules.map((rule) => {
    const valid = input && rule.validator(input);
    return { ...rule, valid };
  });
};

export const isPasswordValid = (processedRules) => {
  return processedRules.every((rule) => rule.valid);
};
