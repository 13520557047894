import React, { useEffect } from "react";
import { ButtonWrap, OTPHeading } from "../components/Common/OTP";
import LinkIcon from "@material-ui/icons/Link";

import { Button, Icons } from "@rww/fe-common";
import LoginPageLayout from "../components/Layout/LoginPageLayout";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  OAUTH_TYPES,
  selectOTP,
  setOAuthType,
  updateOTPEmail,
  updateOTPFirstName,
  updateOTPLastName,
} from "../store/otp.slice";
import authService from "../utils/auth.service";
import Meta from "../components/Meta";

const OAuthRegister = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const otp = useSelector(selectOTP);

  useEffect(() => {
    const getEmail = async () => {
      try {
        const response = await authService.getOAuthDetails();

        const success = response?.data?.success;
        const email = response?.data?.emailAddress;
        const firstName = response?.data?.givenName;
        const lastName = response?.data?.surname;
        const oAuthType = response?.data?.type;

        if (!success) {
          navigate("/oauth-denied?msg=GetEmailFailed", { replace: true });
        } else if (
          !Object.values(OAUTH_TYPES).includes(oAuthType.toLowerCase())
        ) {
          navigate("/oauth-denied?msg=RWWUnexpectedOAuth", { replace: true });
        } else if (!email) {
          navigate("/oauth-denied?msg=RWWMissingEmailAddress", { replace: true });
        } else {
          dispatch(updateOTPEmail(email));
          dispatch(updateOTPFirstName(firstName));
          dispatch(updateOTPLastName(lastName));
          dispatch(setOAuthType(oAuthType));
        }
      } catch (e) {
        console.error(e);
        navigate(
          `/oauth-denied?msg=UnexpectedError&detail=${e.message}`, { replace: true }
        );
      }
    };
    getEmail();
  }, [dispatch, navigate]);

  const handleClick = () => {
    navigate("/otp-email");
  };
  return (
    <LoginPageLayout>
      <Meta
        title="Success | Return from social | redwigwam - Workers"
        description="Registration page for workers looking to find part time work"
        keywords="part time work, temporary work, students, working mums, recently retired, looking for temporary workers"
      />
      <OTPHeading
        icon={<LinkIcon />}
        title={`Connected to ${otp.oAuthType}`}
        subtitle="Next lets verify that email address"
      />

      <ButtonWrap>
        <Button
          endIcon={<Icons.ArrowRight />}
          onClick={handleClick}
          primary
          disabled={!otp.email}
          data-test-id="oauth-submit"
        >Next</Button>
      </ButtonWrap>
    </LoginPageLayout>
  );
};

export default OAuthRegister;
