import { configureStore } from "@reduxjs/toolkit";
import otp from "./otp.slice";
import settingsSlice from "./settings.slice";
import authSlice from "./auth.slice";

export const store = configureStore({
  reducer: {
    [otp.name]: otp.reducer,
    [settingsSlice.name]: settingsSlice.reducer,
    [authSlice.name]: authSlice.reducer,
  },
});
