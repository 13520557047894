export const OTPCodeIcon = () => (
    <svg
      width="56"
      height="31"
      viewBox="0 0 56 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.54545 25.4545H53.4545V30.5455H2.54545V25.4545ZM5.47273 15.1455L7.63636 11.3782L9.8 15.1455L13.1091 13.2364L10.9455 9.46909H15.2727V5.65091H10.9455L13.1091 1.90909L9.8 0L7.63636 3.74182L5.47273 0L2.16364 1.90909L4.32727 5.65091H0V9.46909H4.32727L2.16364 13.2364L5.47273 15.1455ZM22.5273 13.2364L25.8364 15.1455L28 11.3782L30.1636 15.1455L33.4727 13.2364L31.3091 9.46909H35.6364V5.65091H31.3091L33.4727 1.90909L30.1636 0L28 3.74182L25.8364 0L22.5273 1.90909L24.6909 5.65091H20.3636V9.46909H24.6909L22.5273 13.2364ZM56 5.65091H51.6727L53.8364 1.90909L50.5273 0L48.3636 3.74182L46.2 0L42.8909 1.90909L45.0545 5.65091H40.7273V9.46909H45.0545L42.8909 13.2364L46.2 15.1455L48.3636 11.3782L50.5273 15.1455L53.8364 13.2364L51.6727 9.46909H56V5.65091Z"
        fill="#89016F"
      />
    </svg>
  );
  