import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Details from "./Questions/Details";
import Distance from "./Questions/Distance";
import Location from "./Questions/Location";
import Password from "./Questions/Password";
import Terms from "./Questions/Terms";

import { Button, Icons, ProgressBar } from "@rww/fe-common";

import { useFormik } from "formik";

import * as Yup from "yup";
import dataService, { FIELD_CONST } from "../../utils/data.service";
import { useSelector } from "react-redux";
import { selectOTP } from "../../store/otp.slice";
import SignupFinished from "./SignupFinished";
import { ERROR_CONST } from "../../utils/errors";
import { GTM_ACTIONS, GTM_CATEGORIES, sendGTMEvent } from "../../utils/gtm";
import classNames from "classnames";

const SignUpWrap = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
`;
const Header = styled.div`
  color: white;
  background-color: purple;
`;

const HeaderContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
`;

const HeaderTop = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr 50px;
  text-align: center;
  font-size: 14px;
`;

const FormWrap = styled.div`
  max-width: 600px;
  flex-grow: 1;
  margin: 0 auto;
  width: 100%;

  > form {
    height: 100%;
    max-height: 600px;
  }
`;

const BackButton = styled(Button)`
  visibility: hidden;
  &.show {
    visibility: visible;
  }

  svg {
    color: white;
  }
`;

const defaultQuestions = [Details, Password, Location, Distance, Terms];

const oauthQuestions = [Details, Location, Distance, Terms];

export const QUESTIONS = {
  details: "DETAILS",
  password: "PASSWORD",
  location: "LOCATION",
  distance: "DISTANCE",
  terms: "TERMS",
  complete: "COMPLETE",
};

const SignUpForm = () => {
  const [questionIndex, setQuestionIndex] = useState(0);
  const [progress, setProgress] = useState([]);
  const [submitError, setSubmitError] = useState();
  const [finished, setFinished] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const otp = useSelector(selectOTP);

  const questionList = otp.oAuthType ? oauthQuestions : defaultQuestions;

  useEffect(() => {
    let newProgress = [];

    const allButLastQuestion = [...questionList].splice(
      0,
      questionList.length - 1
    );

    allButLastQuestion.forEach((question, index) => {
      if (index < questionIndex) {
        newProgress.push({
          steps: 2,
          completed: 2,
          name: `question-${index + 1}`,
        }); // completed
      } else if (index === questionIndex) {
        newProgress.push({
          steps: 2,
          completed: 1,
          name: `question-${index + 1}`,
        }); // in progress
      } else {
        newProgress.push({
          steps: 2,
          completed: 0,
          name: `question-${index + 1}`,
        }); // un started
      }
    });

    setProgress(newProgress);
  }, [questionIndex, questionList]);

  const nextQuestion = () => {
    const newIndex =
      questionIndex + 1 > questionList.length
        ? questionList.length
        : questionIndex + 1;
    setQuestionIndex(newIndex);
  };

  const prevQuestion = () => {
    if (questionIndex - 1 >= 0) {
      setQuestionIndex(questionIndex - 1);
    }
  };
  const onSubmit = async (values) => {
    try {
      if (!submitting) {
        setSubmitting(true);
        const response = await dataService.register(values);
        if (response?.data?.success) {
          sendGTMEvent(
            GTM_CATEGORIES.registration,
            GTM_ACTIONS.registrationComplete
          );
          setFinished(true);
        } else {
          console.error(response?.data);
          setSubmitError(ERROR_CONST.generic);
        }
        setSubmitting(false);
      }
    } catch (e) {
      console.error(e);
      setSubmitError(ERROR_CONST.generic);
      setSubmitting(false);
    }
  };

  const CurrentQuestion = questionList[questionIndex];

  const {
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    setFieldTouched,
    submitForm,
  } = useFormik({
    initialValues: {
      [FIELD_CONST.email]: otp.email,
      [FIELD_CONST.firstName]: otp.firstName,
      [FIELD_CONST.lastName]: otp.lastName,
      [FIELD_CONST.password]: "",
      [FIELD_CONST.postcode]: "",
      [FIELD_CONST.distance]: 15,
      [FIELD_CONST.terms]: false,
      otpType: otp.oAuthType,
    },
    initialTouched: {
      [FIELD_CONST.firstName]: Boolean(otp.firstName), // field is touched when coming from OAuth
      [FIELD_CONST.lastName]: Boolean(otp.lastName),
    },
    onSubmit: onSubmit,
    validationSchema: Yup.object({
      otpType: Yup.string().nullable(),
      [FIELD_CONST.firstName]: Yup.string()
        .max(64, "First name should be less than 64 characters")
        .required("First name is required"),
      [FIELD_CONST.lastName]: Yup.string()
        .max(64, "Last name should be less than 64 characters")
        .required("Last name is required"),
      [FIELD_CONST.password]: Yup.string().when("otpType", {
        is: (otpType) => otpType,
        then: Yup.string()
          .max(24, "Must be no more than 24 characters")
          .min(8, "Must be at least 8 characters")
          .matches(/\d/, "has at least 1 digit")
          .matches(/\W/, "has at least 1 special character"),
        otherwise: Yup.string()
          .max(24, "Must be no more than 24 characters")
          .min(8, "Must be at least 8 characters")
          .matches(/\d/, "has at least 1 digit")
          .matches(/\W/, "has at least 1 special character")
          .required(),
      }),

      [FIELD_CONST.postcode]: Yup.string()
        .matches(
          //https://ideal-postcodes.co.uk/guides/postcode-validation#simpleregularexpression
          /^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i,
          "Must be valid a postcode"
        )
        .required(),
      [FIELD_CONST.distance]: Yup.number().required(),
    }),
  });

  return (
    <SignUpWrap>
      <Header>
        <HeaderContainer>
          <ProgressBar progress={progress} />

          <HeaderTop>
            <BackButton
              className={classNames({
                show:
                  questionIndex !== questionList.length - 1 &&
                  questionIndex !== 0,
              })}
              disabled={isSubmitting}
              startIcon={<Icons.ArrowLeft />}
              onClick={prevQuestion}
              data-test-id="sign-up-back"
            />

            <p>Profile</p>
          </HeaderTop>
        </HeaderContainer>

      </Header>
      <FormWrap>
        {finished ? (
          <SignupFinished values={values} />
        ) : (
          <form onSubmit={handleSubmit}>
            <CurrentQuestion
              handleChange={handleChange}
              nextQuestion={nextQuestion}
              handleBlur={handleBlur}
              values={values}
              errors={errors}
              submitError={submitError}
              touched={touched}
              setFieldTouched={setFieldTouched}
              isSubmitting={isSubmitting || submitting}
              submitForm={submitForm}
            />
          </form>
        )}
      </FormWrap>
    </SignUpWrap>
  );
};

export default SignUpForm;
