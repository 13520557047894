import { createSlice,createSelector } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    profile: {},
    sso: {
      legacyid: false,
      token: false,
    },
  },
  reducers: {
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    setSSO: (state, action) => {
      state.sso = {
        legacyid: action.payload.id,
        token: action.payload.token,
      };
    },
  },
});

export const { setIsAuthenticated, setProfile, setSSO } = slice.actions;

export const selectAuthSlice = (state) => state[slice.name];

export const selectIsAuthenticated = createSelector(
  selectAuthSlice,
  (slice) => slice.isAuthenticated
);

export const selectProfile = createSelector(
  selectAuthSlice,
  (slice) => slice?.profile ?? {}
);

export const selectSSO = createSelector(
  selectAuthSlice,
  (slice) => slice?.sso ?? {}
);

export default slice;
