import * as Yup from "yup";

import {
  ErrorDisplay,
  Form,
  StyledAnchor,
  StyledLink,
} from "../components/Common";
import { GTM_ACTIONS, GTM_CATEGORIES, sendGTMEvent } from "../utils/gtm";
import React, { useEffect, useRef, useState } from "react";
import authService, { authEndpoints } from "../utils/auth.service";

import { Button, Icons } from "@rww/fe-common";
import { ERROR_CONST } from "../utils/errors";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import LoginPageHeading from "../components/Layout/LoginPageHeading";
import LoginPageLayout from "../components/Layout/LoginPageLayout";
import Meta from "../components/Meta";
import Socials from "../components/Socials";
import { Input } from "@rww/fe-common";
import { executeCaptcha, v2Id } from "../utils/recaptcha";
import styled from "styled-components";
import { useFormik } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";
import useURLParams from "../utils/useURLParams.hook";
import { useCallback } from "react";

const ForgottenLink = styled(StyledLink)`
  display: block;
  color: #666;
`;

const RecaptchaWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2px;
`;

const LoginPage = () => {
  const navigate = useNavigate();

  const [error, setError] = useState();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const formRef = useRef();

  const [URLSearchParams] = useSearchParams();

  const redirectTo = URLSearchParams.get("redirectTo");
  const paramError = URLSearchParams.get("error");

  const { advertId, referralSource } = useURLParams();

  useEffect(() => {
    setError(paramError);
  }, [paramError]);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const handleRegister = () => {
    navigate("/otp-email");
  };

  const doLogin = async () => {
    formRef.current.submit();
  };

  const onSubmit = useCallback(async () => {
    setIsSubmitting(true);
    try {
      const verified = await executeCaptcha("SIGN_IN");

      if (verified) {
        try {
          sendGTMEvent(GTM_CATEGORIES.login, GTM_ACTIONS.login);
          doLogin();
        } catch (e) {
          // an unexpected error
          throw new Error(e);
        }
      } else {
        // captcha has failed, block resubmit ans show error message
        setError(ERROR_CONST.recaptcha);
        setIsSubmitting(false);
      }
    } catch (e) {
      console.error(e);
      // a unexpected error, allow reattempt
      setError(ERROR_CONST.generic);
      setIsSubmitting(false);
    }
  }, []);

  const { values, errors, touched, handleChange, handleBlur } = useFormik({
    initialValues: { username: "", password: "" },
    validationSchema: validationSchema,
  });

  const handleFacebook = useCallback(() => {
    authService.signInFacebook({ redirectTo, advertId, referralSource });
  }, [redirectTo, advertId, referralSource]);

  const handleGoogle = useCallback(() => {
    authService.signInGoogle({ redirectTo, advertId, referralSource });
  }, [redirectTo, advertId, referralSource]);

  return (
    <LoginPageLayout>
      <Meta
        title="Log in here to find part time work | redwigwam - Workers"
        description="Log in page for people looking for part time or temporary work"
        keywords="part time work, temporary work, students, working mums, recently retired, looking for temporary workers"
      />
      <LoginPageHeading
        title="Worker Login"
        subtitle="Enter your details below to login."
      />

      <Form ref={formRef} method="POST" action={authEndpoints.signIn.newWorld}>
        <Input
          label="Email address"
          name="username"
          type="email"
          fullWidth
          autoComplete="email"
          icon={<EmailOutlinedIcon />}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values?.username}
          error={errors.username && touched.username && errors.username}
          data-test-id="login-email"
        />
        <Input
          label="Password"
          name="password"
          type="password"
          fullWidth
          autoComplete="current-password"
          icon={<LockOutlinedIcon />}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values?.password}
          error={errors.password && touched.password && errors.password}
          data-test-id="login-password"
        />
        <input type="hidden" name="redirectTo" value={redirectTo} />
        <input type="hidden" name="advertId" value={advertId} />
        <input type="hidden" name="referralSource" value={referralSource} />

        <ForgottenLink
          to="/password-reset"
          data-test-id="login-forgotten-details"
        >
          Forgotten details?
        </ForgottenLink>
        <RecaptchaWrap id={v2Id} />

        <Button
          primary
          fullWidth
          onClick={onSubmit}
          type="button"
          endIcon={<Icons.ArrowRight />}
          loading={isSubmitting}
          disabled={!values?.username || !values?.password}
          data-test-id="login-submit"
        >Login</Button>
        {error && <ErrorDisplay>{error}</ErrorDisplay>}
      </Form>

      <StyledAnchor onClick={handleRegister} data-test-id="login-registration">
        Don't have a redwigwam account?
      </StyledAnchor>

      <Socials
        handleFacebook={handleFacebook}
        handleGoogle={handleGoogle}
        separatorText="or sign in with"
      />

      <StyledLink to="/magic-link">Magic Link</StyledLink>
    </LoginPageLayout>
  );
};

export default LoginPage;
