import React from "react";

import QuestionWrap from "../QuestionWrap";
import { Input } from "@rww/fe-common";
import { PageHeading, Subheading } from "../CommonQuestionBits";
import { FIELD_CONST } from "../../../utils/data.service";

const Location = ({
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  nextQuestion,
  setFieldTouched,
}) => {
  const onChange = (e) => {
    e.target.value = e.target.value.toUpperCase();
    handleChange(e);
  };

  const isDisabled =
    !touched[FIELD_CONST.postcode] || errors[FIELD_CONST.postcode];

  return (
    <QuestionWrap buttonOnClick={nextQuestion} disabled={isDisabled}>
      <PageHeading>Your location</PageHeading>
      <Subheading>
        So we can send you jobs in your area. We don't need your full address
        for now, just your postcode.
      </Subheading>
      <Input
        type="text"
        name={FIELD_CONST.postcode}
        autoComplete="postal-code"
        fullWidth
        onInput={() => setFieldTouched(FIELD_CONST.postcode, true, true)}
        onChange={onChange}
        onBlur={handleBlur}
        value={values[FIELD_CONST.postcode]}
        label="Postcode"
        error={
          errors[FIELD_CONST.postcode] &&
          touched[FIELD_CONST.postcode] &&
          errors[FIELD_CONST.postcode]
        }
        data-test-id="sign-up-postcode"
      />
    </QuestionWrap>
  );
};

export default Location;
