import React from "react";

import QuestionWrap from "../QuestionWrap";
import { Input } from "@rww/fe-common";
import { PageHeading, Subheading } from "../CommonQuestionBits";
import { FIELD_CONST } from "../../../utils/data.service";

const Details = ({
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  nextQuestion,
  setFieldTouched,
}) => {
  const nextDisabled = Boolean(
    !touched[FIELD_CONST.firstName] ||
      !touched[FIELD_CONST.lastName] ||
      errors[FIELD_CONST.firstName] ||
      errors[FIELD_CONST.lastName]
  );
  return (
    <QuestionWrap buttonOnClick={nextQuestion} disabled={nextDisabled}>
      <PageHeading>Your details</PageHeading>
      <Subheading>We help you find flexible work quickly.</Subheading>
      <Input
        label="First Name"
        fullWidth
        name={FIELD_CONST.firstName}
        onInput={() => setFieldTouched(FIELD_CONST.firstName, true, true)}
        autocomplete="given-name"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values[FIELD_CONST.firstName]}
        error={
          errors[FIELD_CONST.firstName] &&
          touched[FIELD_CONST.firstName] &&
          errors[FIELD_CONST.firstName]
        }
        data-test-id="sign-up-first-name"
      />
      <Input
        fullWidth
        label="Last Name"
        name={FIELD_CONST.lastName}
        onInput={() => setFieldTouched(FIELD_CONST.lastName, true, true)}
        autocomplete="family-name"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values[FIELD_CONST.lastName]}
        error={
          errors[FIELD_CONST.lastName] &&
          touched[FIELD_CONST.lastName] &&
          errors[FIELD_CONST.lastName]
        }
        data-test-id="sign-up-last-name"
      />
    </QuestionWrap>
  );
};
export default Details;
