import React from "react";
import styled from "styled-components";

import MarkdownIt from "markdown-it";
import ins from "markdown-it-ins";

const StyledMarkdown = styled.div`
  max-width: 600px;
  margin: 0 auto;

  table {
    display: block;
    width: 100%;
    overflow: auto;
    border-spacing: 0;
    border-collapse: collapse;

    th {
      font-weight: 600;
    }
    th,
    td {
      padding: 6px 13px;
      border: 1px solid #dfe2e5;
    }
    tr {
      background-color: #fff;
      border-top: 1px solid #c6cbd1;

      &:nth-child(2n) {
        background-color: #f6f8fa;
      }
    }
  }
`;
const Markdown = ({ className, text }) => {
  try {
    if (text) {
      const md = MarkdownIt({ html: true }).use(ins);

      const markdown = md.render(text);

      return (
        <StyledMarkdown
          className={className}
          dangerouslySetInnerHTML={{ __html: markdown }}
        ></StyledMarkdown>
      );
    } else {
      return null;
    }
  } catch (e) {
    console.error(e);
    return null;
  }
};

export default Markdown;
