import axios from "axios";

export const authEndpoints = {
  me: "/Api/me",
  signUp: {
    facebook: "/Api/SignUp/SignInFacebook",
    google: "/Api/SignUp/SignInGoogle",
    getOAuthDetails: "/Api/SignUp/GetOAuthDetails",
  },
  signIn: {
    google: "/Api/SignIn/SignInGoogle",
    facebook: "/Api/SignIn/SignInFacebook",
    newWorld: "/Api/SignIn/signinBasic",
    magicLink: "/Api/SignIn/SignInMagicLinkToken",
  },
  signOut: {
    external: "/Api/SignUp/SignoutExternal",
    newWorld: "/Api/SignIn/SignOut",
  },
  sso: {
    getOWToken: "/Api/SignIn/GenerateOWSSOToken",
  },
  magicLink: {
    request: "/Api/SignIn/SignInMagicLinkRequest",
  },
};

class AuthService {
  registerFacebook = () => {
    window.location.href = authEndpoints.signUp.facebook;
  };
  registerGoogle = () => {
    window.location.href = authEndpoints.signUp.google;
  };
  signInFacebookOAuth = () => {
    window.location.href = process.env.REACT_APP_OLD_WORLD_FACEBOOK;
  };
  signInGoogleOAuth = () => {
    window.location.href = process.env.REACT_APP_OLD_WORLD_GOOGLE;
  };
  getOAuthDetails = () => {
    return axios.get(authEndpoints.signUp.getOAuthDetails);
  };

  signoutExternal = () => {
    return axios.post(authEndpoints.signOut.external);
  };

  signInGoogle = ({ redirectTo, advertId, referralSource }) => {
    const url = new URL(authEndpoints.signIn.google, window.location.origin);

    if (redirectTo) {
      url.searchParams.set("redirectTo", redirectTo);
    }

    if (advertId) {
      url.searchParams.set("advertId", advertId);
    }

    if (referralSource) {
      url.searchParams.set("referralSource", referralSource);
    }
    window.location.href = url.toString();
  };
  signInFacebook = ({ redirectTo, advertId, referralSource }) => {
    const url = new URL(authEndpoints.signIn.facebook, window.location.origin);

    if (redirectTo) {
      url.searchParams.set("redirectTo", redirectTo);
    }

    if (advertId) {
      url.searchParams.set("advertId", advertId);
    }

    if (referralSource) {
      url.searchParams.set("referralSource", referralSource);
    }
    window.location.href = url.toString();
  };

  getProfile = () => {
    return axios.get(authEndpoints.me);
  };

  signInNewWorld = (username, password) => {
    return axios.post(authEndpoints.signIn.newWorld, { username, password });
  };
  getSSOToken = () => {
    return axios.get(authEndpoints.sso.getOWToken);
  };

  postMagicLinkRequest = ({
    emailAddress,
    redirectUrl,
    advertId,
    referralSource,
  }) => {
    return axios.post(authEndpoints.magicLink.request, {
      emailAddress,
      redirectUrl,
      advertId,
      referralSource,
    });
  };
  postSignInMagicLink = (token) => {
    return axios.post(authEndpoints.signIn.magicLink, { token });
  };

  logout = () => {
    return axios.get(authEndpoints.signOut.newWorld);
  };
}

const authService = new AuthService();

export default authService;
