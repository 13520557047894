import axios from "axios";
import Cookies from "js-cookie";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export const advertIdCookieName = "rww-advertId";
export const referralSourceCookieName = "rww-referralSource";
export const hirerSiteIdCookieName = "rww-hirerSiteId";

const termName = "rww-utm_term";
const sourceName = "rww-utm_source";
const mediumName = "rww-utm_medium";

const advertIdValidName = "rww-advertId-valid";

export default function useURLParams() {
  const [searchParams] = useSearchParams();

  const [validAdvertId, setValidAdvertId] = useState(false);

  const paramAdvertId = searchParams.get("advertId");
  const paramReferralSource = searchParams.get("referralSource");
  const paramHirerSiteId = searchParams.get("hirerSiteId");

  
  const paramUtmTerm = searchParams.get("utm_term");
  const paramUtmMedium = searchParams.get("utm_medium");
  const paramUtmSource = searchParams.get("utm_source");

  const cookieAdvertId = Cookies.get(advertIdCookieName);
  const cookieReferralSource = Cookies.get(referralSourceCookieName);
    const cookieHirerSiteId = Cookies.get(hirerSiteIdCookieName);

  const cookieUtmTerm = Cookies.get(termName);
  const cookieUtmSource = Cookies.get(sourceName);
  const cookieUtmMedium = Cookies.get(mediumName);

  useEffect(() => {
    // if param is present, update the cookie
    if (paramAdvertId) {
      Cookies.set(advertIdCookieName, paramAdvertId, { expires: 1 / 24 });
    }

    if (paramReferralSource) {
      Cookies.set(referralSourceCookieName, paramReferralSource, { expires: 1 / 24 });
    }

      if (paramHirerSiteId) {
      Cookies.set(hirerSiteIdCookieName, paramHirerSiteId, { expires: 1 / 24 });
    }


    if (paramUtmTerm) {
      Cookies.set(termName, paramUtmTerm, { expires: 1 / 24 });
    }

    if (paramUtmMedium) {
      Cookies.set(mediumName, paramUtmMedium, {
        expires: 1 / 24,
      });
    }

    if (paramUtmSource) {
      Cookies.set(sourceName, paramUtmSource, {
        expires: 1 / 24,
      });
    }
  }, [
    paramAdvertId,
    paramReferralSource,
    paramUtmMedium,
    paramUtmSource,
    paramUtmTerm,
    paramHirerSiteId
  ]);

  const checkedAdvertIdRef = useRef(false);

  useEffect(() => {

    // check advert id in url -> cookie is valid
    if ((paramAdvertId || cookieAdvertId) && !checkedAdvertIdRef.current) {
      checkedAdvertIdRef.current = true;
      axios
        .get(`/Api/Advert/GetAdvert/${paramAdvertId || cookieAdvertId}`)
        .then((response) => {
          if (response?.data?.success) {
            setValidAdvertId(true);
            Cookies.set(advertIdValidName, true);
          } else {
            throw new Error("advert invalid");
          }
        })
        .catch((e) => {
          setValidAdvertId(false);
          Cookies.set(advertIdValidName, false);
        });
    }
  }, [cookieAdvertId, paramAdvertId]);

  return {
    advertId: paramAdvertId || cookieAdvertId,
    referralSource: paramReferralSource || cookieReferralSource,
    utmTerm: paramUtmTerm || cookieUtmTerm,
    utmMedium: paramUtmMedium || cookieUtmMedium,
    utmSource: paramUtmSource || cookieUtmSource,
    hirerSiteId:paramHirerSiteId || cookieHirerSiteId,
    validAdvertId,
  };
}
