
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import styled from "styled-components";
import Socials from "../../Socials";
import dataService from "../../../utils/data.service";

import { StyledLink, ErrorDisplay } from "../../Common";
import { executeCaptcha, v2Id } from "../../../utils/recaptcha";
import { selectOTP, updateOTPEmail } from "../../../store/otp.slice";
import { otpSteps } from "../../../pages/EmailOTP";
import authService from "../../../utils/auth.service";
import { ButtonWrap, OTPForm } from "../../Common/OTP";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoginPageHeading from "../../Layout/LoginPageHeading";
import { ERROR_CONST } from "../../../utils/errors";
import { GTM_ACTIONS, GTM_CATEGORIES, sendGTMEvent } from "../../../utils/gtm";
import useURLParams from "../../../utils/useURLParams.hook";
import { Button, Checkbox, Icons, Input } from "@rww/fe-common";

const StyledCheckbox = styled(Checkbox)`
  margin: 0 0 16px 0;
  .radio__label {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: #918787;
  }
`;

const AlreadyLink = styled(StyledLink)`
  margin: 24px 0;
`;


const RecaptchaWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2px;
`;

const EnterEmail = ({ setOTPStep }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const { advertId, referralSource, utmMedium, utmTerm, utmSource } =
    useURLParams();

  const otp = useSelector(selectOTP);

  useEffect(() => {
    if (!otp.oAuthType) {
      authService.signoutExternal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendCode = async (values) => {
    try {
      setError(false);
      sendGTMEvent(GTM_CATEGORIES.registration, GTM_ACTIONS.submitEmail);
      dispatch(updateOTPEmail(values?.email));

      const response = await dataService.emailSendOTP(
        values.email,
        utmTerm,
        utmSource,
        utmMedium,
        advertId,
        referralSource
      );

      setSubmitting(false);

      if (response?.data?.verifiedMobile) {
        // user has previously completed both Email && Mobile otp but not fully signed up
        navigate("/sign-up", { replace: true });
      } else if (response?.data?.verifiedEmail) {
        // user has previously completed just Email otp but not fully signed up

        navigate("/otp-mobile", { replace: true });
      } else {
        setOTPStep(otpSteps.enterCode);
      }
    } catch (e) {
      console.error(e);
      setError(ERROR_CONST.generic);
      setSubmitting(false);
    }
  };

  const onSubmit = async (values) => {
    try {
      if (!submitting) {
        setSubmitting(true);

        const verified = await executeCaptcha("OTP_EMAIL");
        if (verified) {
          await sendCode(values);
        } else {
          // captcha has failed, block resubmit ans show error message
          setError(ERROR_CONST.recaptcha);
        }
      }
    } catch (e) {
      console.error(e);
      // a unexpected error, allow reattempt
      setError(ERROR_CONST.generic);
      setSubmitting(false);
    }
  };

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    isSubmitting,
    touched,
    errors,
    isValid,
  } = useFormik({
    validateOnChange: true,
    isInitialValid: false,
    initialValues: {
      email: otp.email,
      dataConsent: false,
    },
    onSubmit,
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Email must be valid")
        .required("Email is required"),
      dataConsent: Yup.boolean().required("Consent is required"),
    }),
  });

  const isOAuth = otp.oAuthType;

  return (
    <>
      <LoginPageHeading
        title="Register"
        subtitle={`${isOAuth ? "Confirm" : "Enter"
          } your email address below to create a new redwigwam account`}
      />

      <OTPForm onSubmit={handleSubmit}>
        <Input
          label="Email address"
          name="email"
          type="email"
          fullWidth
          icon={<EmailOutlinedIcon />}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          error={touched.email && errors.email && errors.email}
          disabled={submitting || isOAuth}
          maxLength="128"
          data-test-id="otp-email-email"
        />

        <StyledCheckbox
          label="I consent to redwigwam storing and processing my personal information."
          name="dataConsent"
          onChange={handleChange}
          onBlur={handleBlur}
          checked={values.dataConsent}
          disabled={submitting}
          size="24px"
          data-test-id="otp-email-consent"
        />

        <RecaptchaWrap id={v2Id} />

        <ButtonWrap>
          <Button
            primary
            fullWidth
            type="submit"
            endIcon={<Icons.ArrowRight />}
            loading={isSubmitting}
            disabled={
              !isValid || !values.dataConsent
            }
            data-test-id="otp-email-submit"
          >Register</Button>
          {error && <ErrorDisplay>{error}</ErrorDisplay>}
        </ButtonWrap>
      </OTPForm>

      {!isOAuth && (
        <>
          <AlreadyLink to="/" data-test-id="otp-email-sign-in">
            Already have a redwigwam account?
          </AlreadyLink>
          <Socials
            handleGoogle={() => authService.registerGoogle()}
            handleFacebook={() => authService.registerFacebook()}
            separatorText="or register with"
          />
        </>
      )}
    </>
  );
};

export default EnterEmail;
