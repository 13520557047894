import React, { useState } from "react";
import { ErrorDisplay, StyledAnchor } from "../../Common";

import styled from "styled-components";
import dataService from "../../../utils/data.service";
import { ButtonWrap, OTPForm, OTPHeading } from "../../Common/OTP";
import { OTPCodeIcon } from "../../../assets/svg/OTPCode";
import { useDispatch, useSelector } from "react-redux";
import {
  resetOTPCode,
  selectOTP,
  updateAttempts,
  updateOTPCode,
} from "../../../store/otp.slice";
import { otpSteps } from "../../../pages/EmailOTP";
import { ERROR_CONST } from "../../../utils/errors";
import { GTM_ACTIONS, GTM_CATEGORIES, sendGTMEvent } from "../../../utils/gtm";
import { CodeInput } from "../CodeInput";
import { Button, Icons } from "@rww/fe-common";

const Attempt = styled.p`
  text-align: center;
  margin: 0 0 24px 0;
  font-size: 12px;
  color: #979797;
`;

const OTPInput = ({ setOTPStep }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const otp = useSelector(selectOTP);

  const handleResend = () => {
    setOTPStep(otpSteps.resend);
  };

  const handleRegistration = (e) => {
    e.preventDefault();
    setError(false);
    setLoading(true);
    dataService
      .mobileVerifyOTP(otp.mobileNumber, otp.email, otp.code)
      .then((response) => {
        if (response?.data?.success) {
          setLoading(false);
          sendGTMEvent(
            GTM_CATEGORIES.registration,
            GTM_ACTIONS.submitMobileCode,
            true
          );

          dispatch(
            updateAttempts({
              attempts: null,
              remainingAttempts: null,
            })
          );
          dispatch(resetOTPCode());
          setOTPStep(otpSteps.success);
        } else {
          const { attempts, remainingAttempts } = response?.data ?? {};
          sendGTMEvent(
            GTM_CATEGORIES.registration,
            GTM_ACTIONS.submitMobileCode,
            false
          );
          dispatch(
            updateAttempts({
              attempts: attempts,
              attemptsRemaining: remainingAttempts,
            })
          );

          if (remainingAttempts === 0) {
            dispatch(
              updateAttempts({
                attempts: null,
                attemptsRemaining: null,
              })
            );
            setOTPStep(otpSteps.resend);
          } else {
            setOTPStep(otpSteps.failed);
          }
        }
      })
      .catch((e) => {
        setLoading(false);

        console.error(e);
        setError(ERROR_CONST.generic);
      });
  };

  const handleChange = (code) => {
    dispatch(updateOTPCode(code));
  };

  return (
    <>
      <OTPHeading
        icon={<OTPCodeIcon />}
        title="Confirm"
        subtitle="Enter the unique code we've just sent to your mobile number."
      />

      <OTPForm onSubmit={handleRegistration}>
        <CodeInput
          fields={4}
          name="otpcode"
          autoComplete
          inputMode="numeric"
          onChange={handleChange}
          value={otp.code}
          data-test-id="otp-phone-code"
        />
        {otp?.attempts && (
          <Attempt data-test-id="otp-phone-code-attempts">
            Attempt {otp.attempts + 1} of {otp.attempts + otp.attemptsRemaining}
          </Attempt>
        )}
        <ButtonWrap>
          <Button
            primary
            fullWidth
            type="submit"
            text="Confirm"
            endIcon={<Icons.ArrowRight />}
            loading={loading}
            disabled={!otp.code}
            data-test-id="otp-phone-code-submit"
          >Confirm</Button>

          {error && <ErrorDisplay>{error}</ErrorDisplay>}
        </ButtonWrap>
      </OTPForm>

      <StyledAnchor onClick={handleResend}>Didn't get a code?</StyledAnchor>
    </>
  );
};

export default OTPInput;
