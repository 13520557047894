import authService from "./auth.service";
import { isDesktop } from "react-device-detect";
import { useCallback } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { ERROR_CONST } from "./errors";

export const enterFullscreen = () => {
  if (!isDesktop) {
    if (document.fullscreenEnabled) {
      let element = document.documentElement;
      const requestMethod =
        element.requestFullScreen ||
        element.webkitRequestFullScreen ||
        element.mozRequestFullScreen ||
        element.msRequestFullScreen;

      requestMethod.call(element);
    }
  }
};

export const exitFullscreen = () => {
  if (!isDesktop) {
    if (document.fullscreenEnabled) {
      let element = document;
      const cancelMethod =
        element.cancelFullScreen ||
        element.webkitCancelFullScreen ||
        element.mozCancelFullScreen ||
        element.exitFullscreen ||
        element.webkitExitFullscreen;

      cancelMethod.call(element);
    }
  }
};

export function isEncoded(uri) {
  return uri !== decodeURIComponent(uri || "");
}

export function fullyDecodeURI(uri) {
  while (isEncoded(uri)) {
    uri = decodeURIComponent(uri);
  }

  return uri;
}

export const useMagicToken = () => {
  const [error, setError] = useState(false);
  const { search, hash } = useLocation();
  const searchParams = new URLSearchParams(search);
  const hashParams = new URLSearchParams(hash.replace("#", "?"));

  const magicToken = hashParams.get("magicToken");
  const magicTokenAlias = hashParams.get("mt");

  const usedToken = magicTokenAlias || magicToken;

  const magicTokenRedirect = searchParams.get("magicTokenRedirect");
  const magicTokenRedirectAlias = searchParams.get("mtr");

  const usedRedirect =
    magicTokenRedirectAlias || magicTokenRedirect || "/signed-in";

  const signInWithToken = useCallback(async (token, redirect) => {
    try {
      const response = await authService.postSignInMagicLink(token);

      if (response?.data?.success) {
        window.location.href = fullyDecodeURI(redirect);
      } else {
        setError(response?.data?.message || "Magic link error");
      }
    } catch (e) {
      setError(ERROR_CONST.magicLink);
    }
  }, []);

  useEffect(() => {
    if (usedToken) {
      signInWithToken(usedToken, usedRedirect);
    }
  }, [usedToken, usedRedirect, signInWithToken]);

  return { magicToken: usedToken, magicTokenRedirect: usedRedirect, error };
};
