export const BackButtonIcon = ({ className }) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16ZM8 1.6C11.528 1.6 14.4 4.472 14.4 8C14.4 11.528 11.528 14.4 8 14.4C4.472 14.4 1.6 11.528 1.6 8C1.6 4.472 4.472 1.6 8 1.6Z"
      fill="currentColor"
    />
    <path
      d="M 8 12 L 8.705 11.295 L 5.915 8.5 L 12 8.5 L 12 7.5 L 5.915 7.5 L 8.705 4.705 L 8 4 L 4 8 L 8 12 Z"
      fill="currentColor"
    ></path>
  </svg>
);
