import styled from "styled-components";
import { Form } from ".";
import { Copy, PageHeading } from "../SignUpForm/CommonQuestionBits";

export const IconCircle = styled.div`
  border-radius: 100%;
  height: 120px;
  width: 120px;
  background: #f4e6f1;
  margin: 56px auto 24px auto;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    width: 50px;
    height: auto;
    color: var(--rww-brand-purple);
  }
`;

const OTPTitle = styled(PageHeading)`
  margin: 0 0 8px 0;
`;

const OTPSubtitle = styled(Copy)`
  margin: 0 auto 24px auto;
  max-width: 75%;
  text-align: center;
  flex-grow: 1;
`;

export const OTPHeading = ({ icon, title, subtitle, className }) => {
  return (
    <div className={className}>
      <IconCircle>{icon}</IconCircle>
      <OTPTitle>{title}</OTPTitle>
      <OTPSubtitle>{subtitle}</OTPSubtitle>
    </div>
  );
};

export const ButtonWrap = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  margin-bottom: 24px;
  flex-direction: column;
`;

export const OTPForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
