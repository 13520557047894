import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-number-input";

import "react-phone-number-input/style.css";
import { Button } from "@rww/fe-common";

export const PageHeading = styled.h1`
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: center;
  color: var(--rww-brand-purple);
  margin-bottom: 16px;
`;

export const Copy = styled.p`
  margin: 0 0 24px 0;
  text-align: center;
`;

export const Logo = styled.img`
  width: 70px;
  margin-top: 32px;
`;

export const Form = styled.form`
  width: 100%;
  display:flex;
  flex-direction: column;
`;

export const AnchorCSS = css`
  font-family: Arimo;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: center;
  color: #89016f;
  text-decoration: none;
  margin: 16px 0;
  cursor: pointer;
`;

export const StyledLink = styled(Link)`
  ${AnchorCSS}
`;

export const StyledAnchor = styled.a`
  ${AnchorCSS}
`;

export const ErrorDisplay = styled.p`
  font-size: 14px;
  color: var(--rww-brand-brightred);
  text-align: center;
`;

export const StyledPhoneInput = styled(PhoneInput)`
  padding: 8px;
  border: 2px solid #c8c3c3;
  border-radius: 8px;

  ${(props) =>
    !props?.$valid &&
    props?.$touched &&
    `
  border-color:var(--rww-brand-brightred);
  `}

  input {
    border: none;
    font-size: 16px;
  }
`;

export const BackButton = styled(Button)`
  font-size: 14px;
  font-weight: 600;
`;


