import React, { useState } from "react";

import LoginPageLayout from "../components/Layout/LoginPageLayout";
import Meta from "../components/Meta";
import OTPEnterCode from "../components/OTP/Mobile/EnterMobileCode";
import OTPEnterMobile from "../components/OTP/Mobile/EnterMobile";
import OTPFailed from "../components/OTP/Mobile/MobileFailed";
import OTPResend from "../components/OTP/Mobile/MobileResend";
import OTPSuccess from "../components/OTP/Mobile/MobileSuccess";
import { selectOTP } from "../store/otp.slice";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export const otpSteps = {
  enterMobile: "enterMobile",
  enterCode: "EnterCode",
  failed: "Failed",
  success: "Success",
  resend: "Resend",
};

const MobileOTP = () => {
  const navigate = useNavigate();

  const otp = useSelector(selectOTP);
  const [otpStep, setOTPStep] = useState(otpSteps.enterMobile);

  const getCurrentView = () => {
    switch (otpStep) {
      case otpSteps.enterMobile:
        return OTPEnterMobile;
      case otpSteps.enterCode:
        return OTPEnterCode;
      case otpSteps.failed:
        return OTPFailed;
      case otpSteps.success:
        return OTPSuccess;
      case otpSteps.resend:
        return OTPResend;
      default:
        return OTPEnterMobile;
    }
  };

  const CurrentView = getCurrentView();

  if (!otp.email) {
    navigate("/", { replace: true });
    return null;
  }

  return (
    <LoginPageLayout>
      <Meta
        title="Mobile verification | redwigwam - Workers"
        description="Registration page for workers looking to find part time work"
        keywords="part time work, temporary work, students, working mums, recently retired, looking for temporary workers"
      />
      <CurrentView setOTPStep={setOTPStep} />
    </LoginPageLayout>
  );
};
export default MobileOTP;
