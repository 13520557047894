import { createSlice } from "@reduxjs/toolkit";

export const OAUTH_TYPES = {
  google: "google",
  facebook: "facebook",
};

const initialState = {
  email: "",
  mobileNumber: "",
  firstName: "",
  lastName: "",
  code: "",
  attempts: null,
  attemptsRemaining: null,
  oAuthType: null,
};

const otpSlice = createSlice({
  name: "otp",
  initialState,
  reducers: {
    setOAuthType: (state, action) => {
      state.oAuthType = action.payload;
    },

    updateOTPEmail: (state, action) => {
      state.email = action.payload;
    },
    updateOTPFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    updateOTPLastName: (state, action) => {
      state.lastName = action.payload;
    },
    updateOTPMobileNumber: (state, action) => {
      state.mobileNumber = action.payload;
    },
    updateOTPCode: (state, action) => {
      state.code = action.payload;
    },
    resetOTPCode: (state) => {
      state.code = "";
    },
    updateAttempts: (state, action) => {
      const { attempts, attemptsRemaining } = action.payload;

      state.attempts = attempts;
      state.attemptsRemaining = attemptsRemaining;
    },
  },
});

export const {
  updateOTPEmail,
  updateOTPFirstName,
  updateOTPLastName,
  updateOTPCode,
  updateAttempts,
  updateOTPMobileNumber,
  setOAuthType,
  resetOTPCode,
} = otpSlice.actions;

export const selectOTP = (state) => state[otpSlice.name];

export default otpSlice;
