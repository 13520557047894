import { Button, Icons } from "@rww/fe-common";
import { Input } from "@rww/fe-common";
import { useFormik } from "formik";
import React, { useState } from "react";
import LoginPageHeading from "../components/Layout/LoginPageHeading";
import LoginPageLayout from "../components/Layout/LoginPageLayout";
import Meta from "../components/Meta";
import * as Yup from "yup";
import dataService from "../utils/data.service";
import { Copy, ErrorDisplay, Form, StyledLink } from "../components/Common";
import { ERROR_CONST } from "../utils/errors";

const PasswordReset = () => {
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const onSubmit = async (values) => {
    try {
      const response = await dataService.resetPassword(values?.email);
      setSubmitting(false);

      if (response?.data?.success) {
        setDone(true);
      } else {
        throw new Error("Unexpected API response");
      }
    } catch (e) {
      console.error(e);
      setError(true);
      setSubmitting(false);
    }
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: { email: "" },
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <LoginPageLayout>
      <Meta title="Password Reset | redwigwam - Workers" />
      <LoginPageHeading
        title="Password Reset"
        subtitle={
          !done &&
          "Enter your email address and we'll send you password reset instructions"
        }
      />
      {!done && (
        <>
          <Form onSubmit={handleSubmit}>
            <Input
              label="Email"
              type="email"
              name="email"
              value={values?.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.email && touched.email && errors.email}
              data-test-id="reset-password-email"
              fullWidth
            />

            <Button
              primary
              type="submit"
              loading={isSubmitting}
              disabled={!values?.email || errors?.email}
              fullWidth
              data-test-id="reset-password-submit"
              endIcon={<Icons.ArrowRight />}
            >Submit</Button>
          </Form>
          {error && <ErrorDisplay>{ERROR_CONST.generic}</ErrorDisplay>}
        </>
      )}
      {done && (
        <>
          <Copy>Done!</Copy>
          <Copy>
            If this email matches a registered account, an email will be sent
            shortly.
          </Copy>
          <Copy>Please check your inbox and spam folders for it.</Copy>

          <Copy>
            Please note, the link to the next step of the process, included in
            the email, will expire in 1 hour if not used.
          </Copy>
        </>
      )}
      <StyledLink to="/" data-test-id="reset-password-back">
        Back to Log-in
      </StyledLink>
    </LoginPageLayout>
  );
};

export default PasswordReset;
