import React from "react";

import QuestionWrap from "../QuestionWrap";
import { Input } from "@rww/fe-common";
import { PageHeading, Subheading } from "../CommonQuestionBits";

import { FIELD_CONST } from "../../../utils/data.service";
import PasswordChecker from "../../PasswordChecker";

const Password = ({
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  nextQuestion,
  setFieldTouched,
}) => {
  const isDisabled =
    !touched[FIELD_CONST.password] || errors[FIELD_CONST.password];
  return (
    <QuestionWrap buttonOnClick={nextQuestion} disabled={isDisabled}>
      <PageHeading>Your Password</PageHeading>
      <Subheading>Strong passwords, better security.</Subheading>
      <Input
        type="password"
        fullWidth
        name={FIELD_CONST.password}
        onInput={() => setFieldTouched(FIELD_CONST.password, true, true)}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values[FIELD_CONST.password]}
        label="Password"
        autoComplete="new-password"
        error={
          errors[FIELD_CONST.password] &&
          touched[FIELD_CONST.password] &&
          errors[FIELD_CONST.password]
        }
        data-test-id="sign-up-password"
      />

      <PasswordChecker password={values[FIELD_CONST.password]} />
    </QuestionWrap>
  );
};

export default Password;
