
import { useSelector, useDispatch } from "react-redux";

import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import { ButtonWrap, OTPHeading } from "../../Common/OTP";
import { resetOTPCode, selectOTP } from "../../../store/otp.slice";
import { otpSteps } from "../../../pages/EmailOTP";
import { Button, Icons } from "@rww/fe-common";

const OTPFailed = ({ setOTPStep }) => {
  const otp = useSelector(selectOTP);
  const dispatch = useDispatch();

  const handleRetry = (e) => {
    dispatch(resetOTPCode());

    setOTPStep(otpSteps.enterCode);
  };

  const handleNewCode = (e) => {
    setOTPStep(otpSteps.resend);
  };

  return (
    <>
      <OTPHeading
        icon={<SentimentVeryDissatisfiedIcon />}
        title="Oops!"
        subtitle={`The code ${otp.code} has expired or did not match. Send a new code to
        your email address.`}
      />
      <ButtonWrap>
        <Button fullWidth onClick={handleNewCode} >Send new code</Button>
        <Button
          primary
          fullWidth
          onClick={handleRetry}
          endIcon={<Icons.ArrowRight />}
          data-test-id="otp-email-failed-submit"
        >Try code again</Button>
      </ButtonWrap>
    </>
  );
};

export default OTPFailed;
