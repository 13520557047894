import { createSlice, createSelector } from "@reduxjs/toolkit";

const initialState = {
  captcha: false,
  redirectTo: false,
  viewedRTWTips: {},
  modals: {
    seenIntro: false,
    seenEvidenceLeft: false,
    seenEvidenceRejected: false,
    seenSpeedModal: false,
  },
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setRedirectTo: (state, action) => {
      state.redirectTo = action.payload;
    },
    setRTWTipViewed: (state, action) => {
      state.viewedRTWTips[action.payload] = true;
    },
    setSkippedSpeedModal: (state, action) => {
      state.modals.seenSpeedModal = action.payload;
    },
    setSeenIntro: (state, action) => {
      state.modals.seenIntro = action.payload;
    },
    setSeenEvidenceLeft: (state, action) => {
      state.modals.seenEvidenceLeft = action.payload;
    },
    setSeenEvidenceRejected: (state, action) => {
      state.modals.seenEvidenceRejected = action.payload;
    },
  },
});

export const {
  updateCaptcha,
  setRedirectTo,
  setRTWTipViewed,
  setSkippedSpeedModal,
  setSeenIntro,
  setSeenEvidenceLeft,
  setSeenEvidenceRejected,
} = settingsSlice.actions;

export const selectSettings = (state) => state[settingsSlice.name];

export const selectRedirectTo = createSelector(
  selectSettings,
  (settings) => settings.redirectTo
);

export const selectViewedRTWTips = createSelector(
  selectSettings,
  (settings) => settings?.viewedRTWTips ?? {}
);

export const selectModals = createSelector(
  selectSettings,
  (settings) => settings?.modals ?? {}
);

export const selectSkippedSpeedModal = createSelector(
  selectModals,
  (modals) => modals?.seenSpeedModal
);

export const selectSeenIntro = createSelector(
  selectModals,
  (modals) => modals?.seenIntro
);

export const selectSeenEvidenceLeft = createSelector(
  selectModals,
  (modals) => modals?.seenEvidenceLeft
);

export const selectSeenEvidenceRejected = createSelector(
  selectModals,
  (modals) => modals?.seenEvidenceRejected
);

export default settingsSlice;
