import React from "react";

import { Button, Icons } from "@rww/fe-common";

import styled from "styled-components";

import DoneIcon from "@material-ui/icons/Done";
import { IconCircle } from "../Common/OTP";

import { PageHeading, Subheading } from "./CommonQuestionBits";
import { FIELD_CONST } from "../../utils/data.service";
import { Copy } from "../Common";
import useURLParams, {
  advertIdCookieName,
  hirerSiteIdCookieName,
  referralSourceCookieName,
} from "../../utils/useURLParams.hook";
import { useCallback } from "react";
import Cookies from "js-cookie";

const Wrap = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  height: calc(100% - 8px);
  padding: 0 24px;
  margin-bottom:8px;
`;

const StyledIconCircle = styled(IconCircle)`
  margin-top: 24px;
`;

const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const CopyWrap = styled.div`
  flex-grow: 1;
  max-height: 600px;
`;

const SignupFinished = ({ values }) => {
  const { validAdvertId, hirerSiteId } = useURLParams();

  let continueLink;
  let copy;
  if (hirerSiteId) {
    continueLink = `/applications/cv/${hirerSiteId}`;
    copy = `Next up: submitting your CV`;
  } else if (validAdvertId) {
    continueLink = "/you-are-here";
    copy = `Next up: submitting your application`;
  } else {
    continueLink = "/rtw";
    copy = `Next up: providing some identity documents`;
  }

  const handleGoToYAH = useCallback(() => {
    Cookies.remove(hirerSiteIdCookieName);
    Cookies.remove(advertIdCookieName);
    Cookies.remove(referralSourceCookieName);
    window.location.href = continueLink;
    return;
  }, [continueLink]);

  return (
    <Wrap>
      <CopyWrap>
        <StyledIconCircle>
          <DoneIcon />
        </StyledIconCircle>
        <PageHeading>
          Yay! All done, {values[FIELD_CONST.firstName]}
        </PageHeading>
        <Subheading>Welcome to redwigwam.</Subheading>
        <Copy>{copy}</Copy>
        <Copy>The process is very simple and should only take 5 minutes</Copy>
      </CopyWrap>
      <ButtonWrap>
        <Button
          primary
          onClick={handleGoToYAH}
          className="tracking-registration-complete"
          endIcon={<Icons.ArrowRight />}

        >Continue</Button>
      </ButtonWrap>
    </Wrap>
  );
};

export default SignupFinished;
