import { Button, Icons } from "@rww/fe-common";
import { Input } from "@rww/fe-common";
import { useFormik } from "formik";
import React, { useState } from "react";
import LoginPageHeading from "../components/Layout/LoginPageHeading";
import LoginPageLayout from "../components/Layout/LoginPageLayout";
import Meta from "../components/Meta";
import * as Yup from "yup";
import dataService from "../utils/data.service";
import { Copy, ErrorDisplay, Form, StyledLink } from "../components/Common";
import { ERROR_CONST } from "../utils/errors";
import { useNavigate, useLocation } from "react-router-dom";
import PasswordChecker from "../components/PasswordChecker";

const ChangePassword = () => {
  const navigate = useNavigate();

  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const onSubmit = async (values) => {
    const confirmToken = params.get("confirm");

    try {
      const response = await dataService.setPassword(
        confirmToken,
        values?.password
      );
      setSubmitting(false);

      if (response?.data?.success) {
        setDone(true);
      } else {
        if (response?.data?.errors?.length > 0) {
          setError(response?.data?.errors);
          return;
        } else {
          if (response?.data?.message === "Invalid confirmation token") {
            setError(ERROR_CONST.invalidPasswordToken);
          } else {
            setError(ERROR_CONST.generic);
          }
        }
      }
    } catch (e) {
      console.error(e);
      setError(ERROR_CONST.generic);
      setSubmitting(false);
    }
  };

  const validationSchema = Yup.object({
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: { password: "", confirmPassword: "" },
    validationSchema: validationSchema,
    onSubmit,
  });

  const invalidToken = error === ERROR_CONST.invalidPasswordToken;

  const handleBackToReset = () => {
    navigate("/password-reset");
  };

  return (
    <LoginPageLayout>
      <Meta title="Password Reset | redwigwam - Workers" />
      <LoginPageHeading
        title="Password Reset"
        subtitle={!done && !invalidToken && "Please enter your new password"}
      />
      {invalidToken && (
        <>
          <ErrorDisplay>{error}</ErrorDisplay>
          {invalidToken && (
            <Button
              primary
              onClick={handleBackToReset}
              data-test-id="change-password-resubmit"
              endIcon={<Icons.ArrowRight />}

            >Resubmit password reset request</Button>
          )}
        </>
      )}
      {!done && !invalidToken && (
        <>
          <Form onSubmit={handleSubmit}>
            <Input
              label="Password"
              type="password"
              name="password"
              autoComplete="new-password"
              value={values?.password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.password && touched.password && errors.password}
              data-test-id="change-password-new"
            />

            <Input
              label="Confirm Password"
              type="password"
              name="confirmPassword"
              value={values?.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                errors.confirmPassword &&
                touched.confirmPassword &&
                errors.confirmPassword
              }
              data-test-id="change-password-confirm"
            />

            <PasswordChecker
              password={values?.password}
              confirmPassword={values?.confirmPassword}
              matchPasswords
            />

            <Button
              primary
              type="submit"
              disabled={
                !values?.password ||
                !values?.confirmPassword ||
                Object.values(errors).length > 0 ||
                isSubmitting
              }
              fullWidth
              data-test-id="change-password-submit"
              endIcon={<Icons.ArrowRight />}
            >Submit</Button>
          </Form>
          {error && typeof error === "object" ? (
            error.map((error) => <ErrorDisplay>{error}</ErrorDisplay>)
          ) : (
            <>
              <ErrorDisplay>{error}</ErrorDisplay>
            </>
          )}
        </>
      )}
      {done && <Copy>Done! Your password has been changed.</Copy>}
      <StyledLink to="/" data-test-id="change-password-back">
        Back to Log-in
      </StyledLink>
    </LoginPageLayout>
  );
};

export default ChangePassword;
