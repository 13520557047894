import { Button, Icons } from "@rww/fe-common";
import { useNavigate } from "react-router-dom";
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";
import { ButtonWrap, OTPHeading } from "../../Common/OTP";
import { resetOTPCode } from "../../../store/otp.slice";
import { useDispatch } from "react-redux";

const OTPSuccess = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleSuccess = (e) => {
    dispatch(resetOTPCode());
    navigate("/sign-up", { replace: true });
  };

  return (
    <>
      <OTPHeading
        icon={<ThumbUpOutlinedIcon />}
        title="Got it!"
        subtitle=" That code matches correctly. Now tell us a little more about yourself
        and setup your profile."
      />
      <ButtonWrap>
        <Button
          onClick={handleSuccess}
          primary
          fullWidth
          endIcon={<Icons.ArrowRight />}
          data-test-id="otp-phone-success-submit"
        >Continue</Button>
      </ButtonWrap>
    </>
  );
};

export default OTPSuccess;
