import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { fullyDecodeURI } from "../utils/general";
import { loginSSO } from "../utils/sso";
import RTWLayout from "../components/Layout/RTWLayout";
import dataService from "../utils/data.service";
import { useCallback } from "react";
import useURLParams, { hirerSiteIdCookieName } from "../utils/useURLParams.hook";
import Cookies from "js-cookie";

export const RTW_STATUSES = {
  approved: "Approved",
  rejected: "Rejected",
  submitted: "Submitted",
  awaiting: "Awaiting",
};

const SignedIn = () => {
  const navigate = useNavigate();

  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const OWPageBase64 = params.get("OWPageBase64");

  const OWPage = atob(OWPageBase64);

  const {hirerSiteId} = useURLParams()

  const savedMagicTokenRedirect = sessionStorage.getItem("magicTokenRedirect");

  const redirectUser = useCallback(async () => {
    try {
      const applicationStatsResponse = await dataService.getApplicationStats();

      const {
        incompleteApplications,
        isRightToWorkComplete,
        totalApplications,
      } = applicationStatsResponse?.data?.response || {};

      if (savedMagicTokenRedirect) {
        sessionStorage.removeItem("magicTokenRedirect");
        window.location.href = fullyDecodeURI(savedMagicTokenRedirect);
        return;
      }
      if (OWPageBase64) {
        loginSSO(OWPage);
        return;
      }

      if(hirerSiteId){
        Cookies.remove(hirerSiteIdCookieName)
        window.location.href=`/applications/cv/${hirerSiteId}`;
        return;
      }

      if (isRightToWorkComplete) {
        if (incompleteApplications > 0) {
          window.location.href = "/you-are-here";
          return;
        }
        window.location.href = "/dashboard";
        return;
      } else {
        // prioritise advert applications over RTW
        if (incompleteApplications > 0) {
          window.location.href = "/you-are-here";
          return;
        }

        // grab RTW progress
        const rtwStats = await dataService.getRTWStats();
        const { evidence } = rtwStats?.data?.response || {};
        const allSubmitted = evidence?.every(
          (item) => item?.status === RTW_STATUSES.submitted
        );

        const hasRTWActionsLeft = evidence?.some(
          (item) =>
            item?.status === RTW_STATUSES.rejected ||
            item?.status === RTW_STATUSES.awaiting
        );

        if (hasRTWActionsLeft) {
          // submitted + rejected / outstanding rtw actions
          window.location.href = "/rtw";
          return;
        }

        if (allSubmitted && totalApplications > 0) {
          // submitted  + >0 advert interest (seen YAH before)
          window.location.href = "/you-are-here";
          return;
        }

        // submitted  + =0 advert interest (NOT seen YAH before)
        window.location.href = "/dashboard";
        return;
      }
    } catch (e) {
      console.error(e);
      navigate("/dashboard");
    }
  }, [savedMagicTokenRedirect, OWPageBase64, hirerSiteId, OWPage, navigate]);

  useEffect(() => {
    redirectUser();
  }, [redirectUser]);

  return <RTWLayout loading overlay loadingText="Redirecting..."></RTWLayout>;
};

export default SignedIn;
