import React from "react";

import QuestionWrap from "../QuestionWrap";
import { Slider } from "@rww/fe-common";
import { PageHeading, Subheading } from "../CommonQuestionBits";
import styled from "styled-components";
import { FIELD_CONST } from "../../../utils/data.service";

const Question = styled.h2`
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  color: var(--rww-brand-purple);
`;
const Miles = styled.p`
  //styleName: H4 header;
  font-family: Arimo;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  margin: 0;
`;
const Value = styled.p`
  //styleName: H1 header;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: center;
  margin: 0;
`;

const Distance = ({ handleChange, handleBlur, values, nextQuestion }) => {
  return (
    <QuestionWrap buttonOnClick={nextQuestion}>
      <PageHeading>Jobs near you</PageHeading>
      <Subheading>Tell us where you would like to work.</Subheading>
      <Question>
        How far can you travel to work from {values[FIELD_CONST.postcode]}?
      </Question>
      <Slider
        name={FIELD_CONST.distance}
        onChange={handleChange}
        onBlur={handleBlur}
        min="1"
        max="30"
        step="1"
        value={values[FIELD_CONST.distance]}
        data-test-id="sign-up-distance"
      />
      <Value data-test-id="sign-up-distance-value">
        {values[FIELD_CONST.distance]}
      </Value>
      <Miles>{values[FIELD_CONST.distance] === 1 ? "Mile" : "Miles"}</Miles>
    </QuestionWrap>
  );
};
export default Distance;
