export const ERROR_CONST = {
  generic:
    "Something went wrong, please try again. If the problem persists please contact the redwigwam team using the live chat on our website - www.redwigwam.com",
  recaptcha: "Something has gone wrong, please try again on another device",
  terms:
    "We're having problems displaying the terms and conditions at the moment, please retry or try again a little later",
  invalidCredentials: "Account not recognised",
  invalidPasswordToken:
    "Unfortunately the link in your password reset email has expired, please resubmit your request",
  magicLink: "This magic link has potentially expired, please try again",
  numberInUse:
    "This number is registered against another account, please provide another",
    tooManyAttempts:"Too many requests have been made, please wait"
};
