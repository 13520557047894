import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import loginLogo from "../../../assets/images/login-logo.png";
import dataService from "../../../utils/data.service";

import {
  PageHeading,
  Copy,
  Logo,
  ErrorDisplay,
  StyledPhoneInput,
  StyledAnchor,
} from "../../Common";
import { executeCaptcha } from "../../../utils/recaptcha";
import { selectOTP, updateOTPMobileNumber } from "../../../store/otp.slice";
import { otpSteps } from "../../../pages/EmailOTP";
import { ButtonWrap, OTPForm } from "../../Common/OTP";
import styled from "styled-components";
import { ERROR_CONST } from "../../../utils/errors";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { GTM_ACTIONS, GTM_CATEGORIES, sendGTMEvent } from "../../../utils/gtm";
import { Button, Icons } from "@rww/fe-common";

const Hint = styled.p`
  color: #8c8181;
  margin: 0;
  font-size: 14px;
  margin-bottom: 24px;
  text-align: center;
`;

const EnterEmail = ({ setOTPStep }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const [touched, setTouched] = useState(false);

  const otp = useSelector(selectOTP);

  const sendCode = async () => {
    setError(false);
    return dataService
      .mobileSendOTP(otp.mobileNumber, otp.email)
      .then((response) => {
        // user has previously completed mobile OTP but not fully signed up

        sendGTMEvent(GTM_CATEGORIES.registration, GTM_ACTIONS.submitMobile);
        setSubmitting(false);

        if (
          response?.data?.message ===
          "Number is already verified with another account"
        ) {
          setLoading(false);
          setError(ERROR_CONST.numberInUse);
          return;
        }

        if (
          response?.data?.message === "You must wait until attempting to resend"
        ) {
          setLoading(false);
          setError(ERROR_CONST.tooManyAttempts);
          return;
        }

        if (response?.data?.verifiedMobile) {
          // skip OTP
          navigate("/sign-up", { replace: true });
        } else {
          setOTPStep(otpSteps.enterCode);
        }
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
        setSubmitting(false);

        setError(ERROR_CONST.generic);
      });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      if (!otp.mobileNumber) {
        // skip to sign up. this step is optional
        navigate("/sign-up", { replace: true });
        return;
      }

      if (!submitting) {
        setSubmitting(true);
        // already verified, no need to do it again
        await sendCode();
      } else {
        setLoading(true);
        const verified = await executeCaptcha("OTP_MOBILE");

        if (verified) {
          await sendCode();
        } else {
          // captcha has failed, block resubmit ans show error message
          setLoading(false);
          setError(ERROR_CONST.recaptcha);
        }
      }
    } catch (e) {
      console.error(e);
      // a unexpected error, allow reattempt
      setError(ERROR_CONST.generic);
      setLoading(false);
      setSubmitting(false);
    }
  };

  const handleNumberChange = (number) => {
    dispatch(updateOTPMobileNumber(number));
  };

  const handleSkip = () => {
    navigate("/sign-up");
  };

  const isValid = isPossiblePhoneNumber(otp.mobileNumber ?? "");

  return (
    <>
      <Logo src={loginLogo} alt="redwigwam logo" />
      <PageHeading>Alert preferences</PageHeading>
      <Copy>
        We'll send alerts and news to the email address you provided earlier.
      </Copy>
      <Copy>
        We can also send alerts to your mobile, if you provide your number
        below.
      </Copy>

      <OTPForm onSubmit={handleSubmit}>
        <StyledPhoneInput
          onChange={handleNumberChange}
          value={otp.mobileNumber ?? undefined}
          autoComplete="tel"
          defaultCountry="GB"
          type="tel"
          placeholder="Mobile Number"
          inputMode="numeric"
          $valid={isValid}
          onBlur={() => setTouched(true)}
          $touched={touched}
          data-test-id="otp-phone-number"
        />
        <Hint>Include country code e.g. +44 7777 888888</Hint>
        <ButtonWrap>
          <Button
            primary
            fullWidth
            type="submit"
            endIcon={<Icons.ArrowRight />}
            loading={loading || submitting}
            disabled={!otp.mobileNumber || !isValid}
            data-test-id="otp-phone-submit"
          >Confirm</Button>

          {error && <ErrorDisplay>{error}</ErrorDisplay>}
          <StyledAnchor onClick={handleSkip} data-test-id="otp-phone-skip">
            Skip, I'll enter my mobile number later
          </StyledAnchor>
        </ButtonWrap>
      </OTPForm>
    </>
  );
};

export default EnterEmail;
