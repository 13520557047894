import { useNavigate } from "react-router-dom";
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";
import { ButtonWrap, OTPHeading } from "../../Common/OTP";
import { resetOTPCode } from "../../../store/otp.slice";
import { useDispatch } from "react-redux";
import { Button, Icons } from "@rww/fe-common";

const OTPSuccess = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleSuccess = (e) => {
    dispatch(resetOTPCode());

    navigate("/otp-mobile");
  };

  return (
    <>
      <OTPHeading
        icon={<ThumbUpOutlinedIcon />}
        title="Got it!"
        subtitle="All linked-up and ready to go. Now tell us how you would like to receive alerts."
      />
      <ButtonWrap>
        <Button
          onClick={handleSuccess}
          primary
          fullWidth
       
          endIcon={<Icons.ArrowRight />}
          data-test-id="otp-email-success-button"
        >Continue</Button>
      </ButtonWrap>
    </>
  );
};

export default OTPSuccess;
