import { CircularProgress } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { BackButtonIcon } from "../../assets/svg/BackButton";

const Wrap = styled.div`
  height: 100%;
  background-color: var(--rww-brand-purple);
`;

const Container = styled.div`
  height: 100%;
  max-width: 600px;
  margin: 0 auto;

  display: grid;
  grid-template-rows: auto 1fr;
`;

const Heading = styled.div`
  color: white;
  padding: 16px 0;
  text-align: center;

  display: grid;
  grid-template-columns: 48px 1fr 48px;
`;

const Children = styled.div`
  background-color: #f4f3f3;
  padding: 0 24px 24px 24px;
  display: grid;
  grid-template-rows: 1fr auto;

  position: relative;

  &.overlay-children {
    margin-top: 24px;
    background-color: white;
    border-radius: 16px 16px 0 0;
    padding: 24px;
  }
`;

const StyledBackButton = styled.div`
  padding-left: 8px;
  cursor: pointer;
  ${(props) => !props?.onClick && `visibility:hidden;`}
`;

const LoadingWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 16px 16px 0 0;
`;

const LoadingAnimation = styled(CircularProgress)`
  color: var(--rww-brand-purple);
`;

const LoadingText = styled.span`
  margin-top: 8px;
  font-size: 14px;
`;

const RTWLayout = ({
  children,
  handleBack,
  overlay,
  loading,
  loadingText = "Loading...",
}) => {
  return (
    <Wrap>
      <Container>
        {!overlay ? (
          <Heading>
            <StyledBackButton onClick={handleBack} data-test-id="rtw-back">
              <BackButtonIcon />
            </StyledBackButton>
            Right to Work
          </Heading>
        ) : (
          <div />
        )}

        <Children className={overlay && "overlay-children"}>
          {loading && (
            <LoadingWrap data-test-id="rtw-loading">
              <LoadingAnimation color="inherit" />
              <LoadingText>{loadingText}</LoadingText>
            </LoadingWrap>
          )}
          {children}
        </Children>
      </Container>
    </Wrap>
  );
};

export default RTWLayout;
