import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import LoadingSplash from "./components/Auth/LoadingSplash";
import ChangePassword from "./pages/ChangePassword";
import EmailOTP from "./pages/EmailOTP";
import LoginPage from "./pages/Login";
import MagicLink from "./pages/MagicLink";
import MobileOTP from "./pages/MobileOTP";
import OAuthDenied from "./pages/OAuthDenied";
import OAuthRegister from "./pages/OAuthRegister";
import PasswordReset from "./pages/PasswordReset";
import SignedIn from "./pages/SignedIn";
import SignUpPage from "./pages/SignUp";
import AuthRoute from "./components/Auth/AuthRoute";
import {
  selectIsAuthenticated,
  setIsAuthenticated,
  setProfile,
} from "./store/auth.slice";
import authService from "./utils/auth.service";
import dataService from "./utils/data.service";
import Logout from "./pages/Logout";
import useURLParams from "./utils/useURLParams.hook";

function App() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [checkingAuth, setCheckingAuth] = useState(true);
  const [splashWait, setSplashWait] = useState(true);

  useURLParams();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        if (!isAuthenticated) {
          authService
            .getProfile()
            .then((response) => {
              dispatch(setIsAuthenticated(true));
              dispatch(setProfile(response?.data));

              setCheckingAuth(false);
            })
            .catch((e) => {
              dispatch(setIsAuthenticated(false));
              setCheckingAuth(false);
            });
        } else {
          setCheckingAuth(false);
        }
      } catch (e) {
        console.error(e);
        setCheckingAuth(false);
      }
    };

    checkAuth();

    if (process.env.NODE_ENV !== "production") {
      window.resetUserRTW = () => {
        if (
          window.confirm(
            "Are you sure you want to reset this user's RTW progress?"
          )
        ) {
          dataService
            .resetUserRTW()
            .then(() => {
              alert("user reset");
              window.location.reload();
            })
            .catch((e) => {
              console.error(e);
            });
        }
      };
    }

    setTimeout(() => {
      setSplashWait(false);
    }, 200);
  }, [dispatch, isAuthenticated]);

  if (checkingAuth || splashWait) {
    return <LoadingSplash />;
  }

  return (
    <Routes>
      <Route index element={<LoginPage />} />
      <Route path="/sign-up" element={<SignUpPage />} />
      <Route path="/password-reset" element={<PasswordReset />} />
      <Route path="/change-password" element={<ChangePassword />} />
      <Route path="/otp-email" element={<EmailOTP />} />
      <Route path="/otp-mobile" element={<MobileOTP />} />
      <Route path="/oauth-register" element={<OAuthRegister />} />
      <Route path="/oauth-denied" element={<OAuthDenied />} />
      <Route path="/magic-link" element={<MagicLink />} />
      <Route path="/ml" element={<MagicLink />} />

      <Route element={<AuthRoute />}>
        <Route path="/signed-in" element={<SignedIn />} />
        <Route path="/logout" element={<Logout />} />
      </Route>

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default App;
