export const OpenEmailIcon = () => (
  <svg
    width="56"
    height="54"
    viewBox="0 0 56 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M55.972 19.6C55.972 17.584 54.936 15.82 53.34 14.84L28 0L2.66 14.84C1.064 15.82 0 17.584 0 19.6V47.6C0 50.68 2.52 53.2 5.6 53.2H50.4C53.48 53.2 56 50.68 56 47.6L55.972 19.6ZM50.372 19.6V19.628L28 33.6L5.6 19.6L28 6.496L50.372 19.6ZM5.6 47.6V26.152L28 40.208L50.372 26.236L50.4 47.6H5.6Z"
      fill="#89016F"
    />
  </svg>
);
