export const GTM_ACTIONS = {
  submitEmail: "Submit Email",
  submitEmailCode: "Submit Email Code",
  submitMobile: "Submit Mobile",
  submitMobileCode: "Submit Mobile Code",
  registrationComplete: "Registration Complete",
  login: "Log in",
  rtw:{
    landing:"Landing",
    speedTestSuccess:"Speed Test - Success",
    speedTestFail:"Speed Test - Fail",
    videoRecordPreStart:"Video Record - Pre-start",
    videoRecordStart:"Video Record - Start",
    videoRecordCancel:"Video Record - Cancel",
    videoRecordFinish:"Video Record - Finish",
    videoSubmitStart:"Video - Submit - Start",
    videoSubmitFinish:"Video - Submit - Finish",
    videoSubmitError:"Video - Submit - Error",
    imageCaptureStart:"Image - Capture - Start",
    imageCaptureCaptured:"Image - Capture - Captured",
    imageCaptureRetry:"Image - Capture - Retry",
    imageSubmitStart:"Image - Submit - Start",
    imageSubmitFinish:"Image - Submit - Finish",
    imageSubmitError:"Image - Submit - Error",
    routeSelect:"Route - Select",
    regionSelect:"Region - Select",
    shareCodeStart:"Share Code - Start",
    shareCodeSubmit:"Share Code - Submit",
    shareCodeError:"Share Code - Error",
    quitPageReturn:"Page - Quit - Return",
    pages:{
      welcome:"Page - Status - Welcome",
      returning:"Page - Status - Returning",
      validating:"Page - Status - Validating",
      video:"Page - Video",
      image:"Page - Image",
      shareCode:"Page - Share Code",
      quit:"Page - Quit",
      complete:"Page - Complete",
      unsupported:"Page - Unsupported",
      mobileQR:"Page - Mobile QR",
    }
  }
};

export const GTM_CATEGORIES = {
  registration: "Registration",
  login: "Log in",
  rtw:"RTW",
};

export const sendGTMEvent = (
  eventCategory,
  eventAction,
  eventLabel,
  eventValue
) => {
  try {
    if(window.dataLayer){
      window.dataLayer.push({
        event: "WPGAE",
        eventCategory,
        eventAction,
        eventLabel,
        eventValue,
      });
    }else{
      console.warn("[GTM] no datalayer")
    }

  } catch (e) {
    console.error(e);
  }
};
