import { Button, Icons } from "@rww/fe-common";
import React, { useState } from "react";
import { StyledPhoneInput } from "../../Common";
import { useDispatch, useSelector } from "react-redux";

import dataService from "../../../utils/data.service";
import { ButtonWrap, OTPForm, OTPHeading } from "../../Common/OTP";
import { OpenEmailIcon } from "../../../assets/svg/OpenEmailIcon";
import { ErrorDisplay } from "../../Common";
import {
  resetOTPCode,
  selectOTP,
  updateOTPMobileNumber,
} from "../../../store/otp.slice";
import { otpSteps } from "../../../pages/EmailOTP";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import { ERROR_CONST } from "../../../utils/errors";

const OTPResend = ({ setOTPStep }) => {
  const dispatch = useDispatch();
  const otp = useSelector(selectOTP);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  const handleSubmit = (e) => {
    e.preventDefault();
    setError(false);
    setLoading(true);

    dataService
      .mobileSendOTP(otp.mobileNumber, otp.email)
      .then((response) => {

        if (
          response?.data?.message ===
          "Number is already verified with another account"
        ) {
          setLoading(false);
          setError(ERROR_CONST.numberInUse);
          return;
        }

        if (
          response?.data?.message ===
          "You must wait until attempting to resend"
        ) {
          setLoading(false);
          setError(ERROR_CONST.tooManyAttempts);
          return;
        }

        // user has previously completed mobile OTP but not fully signed up
        if (response?.data?.verifiedSMS) {
          // skip OTP
          navigate("/sign-up", { replace: true });
        } else {
          dispatch(resetOTPCode());
          setOTPStep(otpSteps.enterCode);
        }
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
        setError(ERROR_CONST.generic);
      });
  };

  const handleNumberChange = (number) => {
    dispatch(updateOTPMobileNumber(number));
  };

  return (
    <>
      <OTPHeading
        icon={<OpenEmailIcon />}
        title="Resend"
        subtitle="Update your mobile number below if it's not correct"
      />

      <OTPForm onSubmit={handleSubmit}>
        <StyledPhoneInput
          onChange={handleNumberChange}
          value={otp.mobileNumber}
          autoComplete="tel"
          defaultCountry="GB"
          type="tel"
          placeholder="Mobile Number"
          data-test-id="otp-phone-change-number"
        />
        <ButtonWrap>
          <Button
            primary
            fullWidth
            type="submit"

            endIcon={<Icons.ArrowRight />}
            loading={loading}
            disabled={
              !otp.mobileNumber ||
              !isValidPhoneNumber(otp.mobileNumber)
            }
            data-test-id="otp-phone-change-number-submit"
          >Send new code</Button>
          {error && <ErrorDisplay>{error}</ErrorDisplay>}
        </ButtonWrap>
      </OTPForm>
    </>
  );
};

export default OTPResend;
