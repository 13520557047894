import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Meta from "../components/Meta";
import SignUpForm from "../components/SignUpForm";
import { selectOTP } from "../store/otp.slice";

const SignUpPage = () => {
  const navigate = useNavigate();

  const otp = useSelector(selectOTP);

  useEffect(() => {
    if (!otp.email) {
      navigate("/", { replace: true });
    }
  }, [navigate, otp.email]);

  if (!otp.email) {
    return null;
  }

  return (
    <>
      <Meta
        title=" Register here to find part-time jobs | redwigwam - Workers"
        description="Registration page for workers looking to find part time work"
        keywords="part time work, temporary work, students, working mums, recently retired, looking for temporary workers"
      />

      <SignUpForm />
    </>
  );
};

export default SignUpPage;
