import React from "react";
import { validatePassword } from "../../utils/validators";
import styled, { css } from "styled-components";

import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";

const Wrap = styled.div`
  margin-bottom: 16px;
`;
const iconCss = css`
  width: 16px !important;
  height: 16px !important;
  margin-right: 8px;
`;

const ValidIcon = styled(CheckCircleOutlineIcon)`
  ${iconCss}
  color: var(--rww-brand-green);
`;

const InvalidIcon = styled(CancelOutlinedIcon)`
  ${iconCss}
  color:  #cac5c5;
`;

const PasswordRule = styled.p`
  display: flex;
  align-items: center;
  margin: 0 0 8px 0;
`;

const PasswordChecker = ({ password, confirmPassword, matchPasswords }) => {
  const checkedRules = validatePassword(password);

  return (
    <Wrap>
      <p>Your password must include the following:</p>
      {checkedRules.map(({ label, valid }) => (
        <PasswordRule key={label}>
          {valid ? <ValidIcon /> : <InvalidIcon />}
          {label}
        </PasswordRule>
      ))}
      {matchPasswords && (
        <PasswordRule>
          {password === confirmPassword && password !== "" && password ? (
            <ValidIcon />
          ) : (
            <InvalidIcon />
          )}
          Passwords must match
        </PasswordRule>
      )}
    </Wrap>
  );
};

export default PasswordChecker;
